import Header from '../../components/Header'
import FormDetalleActividadActivaciones from '../../components/registroActividad/FormDetalleActividadActivaciones'

const PageDetalleActividad = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Activaciones en la Web'}
      />
      <FormDetalleActividadActivaciones />
    </div>
  )
}

export default PageDetalleActividad
