import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";


async function setUpdateUser(dataParams) {

    const resp = await axios.put(`${baseUrl.url}usuario/cmdep`, dataParams);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}


const FormUsuarioConfg = ({ data, cm }) => {
    const [show] = useState(true);
    const [canalList, setCanalList] = useState([]);
    const [dpList, setDpList] = useState([]);
    const [idCanalComercializadorDepartamento, setIdCanalComercializadorDepartamento] = useState([]);
    const [confgData, setConfgData] = useState({
        id_canal: '',
        id_cm: data.id_cm,
        canal: data.canal,
        cm: data.comercializador,
    });

    

    const handleClose = () => {
        window.location.reload();
    };

    const handleChangeCM = (e) => {
        const { name, value } = e.target;
        const id = e.target.options[e.target.selectedIndex].getAttribute('data-id');
        setConfgData((prevData) => ({
            ...prevData,
            [name]: value,
            [`id_${name}`]: id,
        }));
    };

    const handleSend = async () => {
        const validate = await setUpdateUser({
            idCanalComercializadorDepartamento,
            codVendedor: data.codVendedor
        });

        if (validate.status === 200) {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            reloadWindows();
        } else {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }

    const handleChangeDp = (e) => {
        const { value } = e.target;
        setIdCanalComercializadorDepartamento(value);
    }

    useEffect(() => {
        getChannelsByCm();
        getDpByCm();
    }, [confgData]);

    const getChannelsByCm = async () => {
        try {
            if (confgData.cm.trim() !== '') {
                await getChbyCm();
            }
        } catch (error) {
            console.error("Error al obtener los canales segun cm:", error);
        }
    };

    const getDpByCm = async () => {
        try {
            if (confgData.canal.trim() !== '' && confgData.cm.trim() !== '') {
                await fetchDpByCM();
            }
        } catch (error) {
            console.error("Error al obtener los departamentos:", error);
        }
    };


    const getChbyCm = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}cm/ch`, {
                params: {
                    id_cm: confgData.id_cm
                }
            });

            if (response.data.status === 200) {
                setCanalList(response.data.data);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchDpByCM = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}cm/depcm`, {
                params: {
                    cm: confgData.cm,
                    canal: confgData.canal
                }
            });

            if (response.data.status === 200) {
                setDpList(response.data.data);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Error in fetchDpByCM:", error);
        }
    };


    return (
        <Modal show={show} className="custom-modal-width mt-4">
            <div style={{ maxWidth: "556px" }}>
                <form onSubmit={handleSend}>
                    <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="ChangePasswordLabel">Editar Informacion del Usuario</h1>
                            </div>

                            <div className="modal-body">
                                <div className="float-container ">
                                    <select name="cm" onChange={handleChangeCM} value={confgData.cm} id="form-cm" className="form-control form-control-lg">
                                        {cm.map((cm) => (
                                            <option key={cm.value} value={cm.label} data-id={cm.value} >{cm.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="float-container ">

                                    {canalList.length > 0 ? (
                                        <select name="canal" onChange={handleChangeCM} id="form-canal" value={confgData.canal} className="form-control form-control-lg">
                                            <option key="0" value="0">Seleccione un canal...</option>
                                            {canalList.map((ch) => (
                                                <option key={ch.idCanal} value={ch.nombre} data-id={ch.idCanal}>{ch.nombre}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <hr />
                                    )}

                                </div>

                                <div className="float-container ">

                                    {
                                        dpList.length > 0 ? (
                                            <select name="cm" id="form-cm" onChange={handleChangeDp} className="form-control form-control-lg">
                                                <option key="0" value="0">Seleccione un departamento</option>
                                                {dpList.map((dp) => (
                                                    <option key={dp.id_tbl_canal_cm_dep} value={dp.id_tbl_canal_cm_dep}>
                                                        {dp.departamento}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <hr />
                                        )
                                    }


                                </div>


                            </div>
                            <div id='alertDivChannel'></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="text-center">
                        <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleClose} >Cancelar</button>
                        <button id="btnSave" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleSend} >Actualizar</button>
                    </Modal.Footer>
                </form>
            </div>
        </Modal>

    );
}


export default FormUsuarioConfg;