import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function CreateTrxActCom(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/create`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateUuid(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-uuid`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataOcr(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-ocr`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataActMan(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-activation`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataDonor(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-donor`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataCip(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-cip`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataPdf(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-pdf`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataActivacionPorta(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-porta`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function CreateDataSolicitud(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}trx/update-data-solicitud`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function GestionReciente(telefono) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl.url}trx/gestion-reciente/${telefono}`)
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
export async function obtenerIdGestion(trx) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl.url}obtener-id-gestion/${trx}`)
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
