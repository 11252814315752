import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";

async function setUpdateChannel(dataParams) {

    const resp = await axios.put(`${baseUrl.url}channel`, dataParams);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}

const FormCanalEdit = ({data}) => {
    const [show] = useState(true);
    const [id, setId] = useState(data.id || '');
    const [nombre, setNombre] = useState(data.nombre || ''); 
    const [detalle, setDetalle] = useState(data.detalle || '');
    const handleClose = () => window.location.reload();

    const handleUpdate = async e => {
        e.preventDefault();

        const validate = await setUpdateChannel({
            nombre,
            detalle,
            id
        });

        if (validate.status === 200) {
            document.getElementById("btnUpdate").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            document.getElementById(`alertDivModal`).innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            reloadWindows();
        } else {
            document.getElementById(`alertDivModal`).innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }

    useEffect(() => {
        FloatLabel.init();
    }, [])

    return (
        <>
            <Modal show={show} className="custom-modal-width mt-4">
                <div style={{ maxWidth: "556px" }}>
                    <form onSubmit={handleUpdate}>
                        <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="ChangePasswordLabel">Editar Canal</h1>
                                </div>

                                <div className="modal-body">
                                    <div className="float-container float-container-form">
                                        <label htmlFor={"form-nombre"}>NOMBRE</label>
                                        <input type="text" id="form-nombre" name="form-nombre" data-placeholder="" autoComplete="off" required onChange={e => setNombre(e.target.value)} className="border-input form-control form-control-lg" value={nombre} />
                                    </div>
                                    <div className="float-container float-container-form">
                                        <label htmlFor={"form-detalle"}>DETALLE</label>
                                        <input type="text" id="form-detalle" name="form-detalle" data-placeholder="" autoComplete="off" onChange={e => setDetalle(e.target.value)} className="border-input form-control form-control-lg" value={detalle} />
                                    </div>
                                </div>
                                <div id={`alertDivModal`}></div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleClose}>Salir</button>
                            <button id="btnUpdate" className="btn_verde btn-lg btn-block border-boton mb-3" type="submit">Actualizar</button>
                        </Modal.Footer>
                    </form>
                </div>
            </Modal>


        </>

    );


}

export default FormCanalEdit; 