import { useState } from 'react';
import Header from '../../components/Header'
import { Navigate } from 'react-router-dom';
import FormConsultaHomeClausula from '../../components/consultaHome/FormConsultaHomeClausula';
import FormConsultaHomeFirma from '../../components/consultaHome/FormConsultaHomeFirma';

const PageConsultaHomeClausulaFirma = () => {
  const data = sessionStorage.getItem("data_perfilamiento");
  const [step, setStep] = useState(1);

  if (!data) {
    return <Navigate to="/consultahome" />;
  }

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  /* step 1, 3 y 4 son las clausulas, step 2 y 5 son las firmas */
  const stepsMap = {
    1: <FormConsultaHomeClausula step={step} setStep={handleStepChange} />,
    2: <FormConsultaHomeFirma step={step} setStep={handleStepChange} />,
  };

  let componentToRender = stepsMap[step] || (
    <>
      {sessionStorage.removeItem("data_perfilamiento")}
      <Navigate to="/" />
    </>
  );

  return (
    <div>
      <Header
        title={'Internet Hogar'}
      />
      {componentToRender}
    </div>
  );
}

export default PageConsultaHomeClausulaFirma;