import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import imgCda2 from "../img/acp-2.svg";
import imgCda from "../img/cda.svg";

const ModalObtenerCda = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className='btn-abrir-tyc' onClick={handleShow}>
        ¿Cómo obtener el número CDA?
      </div>

      <Modal show={show} onHide={handleClose} className="custom-modal-width mt-4">
        <div style={{ maxWidth: "556px" }} className="fondo-azul">
          <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
            <div>
              <h2 className="titulo-moda-cda mt-3">
                Con tu SIM marca <b>*686# + LLAMAR</b>
              </h2>
              <img
                src={imgCda2}
                alt="imagen número telefono"
                className="w-100 mt-3"
              ></img>
              <h2 className="titulo-moda-cda mt-4">
                O encuentralo en la parte posterior del empaque de tu Tarjeta SIM
              </h2>
              <img
                src={imgCda}
                alt="imagen número telefono"
                className="w-100 mt-4"
              ></img>
              <Button className="btn-azul-blanco mt-4 mb-3" onClick={handleClose}>
                Cerrar
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ModalObtenerCda;
