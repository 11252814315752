import axios from "axios";
import { baseUrl } from "../components/baseURL";

export function validarMaxLineas(dui) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl.url + 'validarMaxLineas/' + dui)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
