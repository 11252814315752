import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";

// AXIOS Create User
async function setCreateUser(dataParams) {

    const resp = await axios.post(`${baseUrl.url}usuario`, dataParams);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}

const handleClose = () => window.location.reload();

const FormUsuario = ({ cm }) => {
    const [usuario, setUsuario] = useState({
        codVendedor: '',
        nombre: '',
        clave: 'Movistar',
        correo: '',
        documento: '',
        telefono: '',
        idCanalComercializadorDepartamento: '',
        idEstado: 3,
        idRol: '',
    });
    const [show] = useState(true);
    const [canalList, setCanalList] = useState([]);
    const [cmData, setCMData] = useState({
        canal: '',
        cm: '',
        id_canal: '',
        id_cm: '',
    });
    const [dpList, setDpList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const handleClose = () => {
        setCurrentStep(1);
        window.location.reload();
    };
    const handleSend = async () => {
        const validate = await setCreateUser({
            usuario
        });

        if (validate.status === 201) {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            reloadWindows();
        } else {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }

    const handlePrev = () => {
        currentStep === 1 ?
            handleClose() :
            setCurrentStep(currentStep - 1)
    };
    const handleNext = () => {
        currentStep !== 3 ?
            setCurrentStep(currentStep + 1) :
            handleSend()
    };

    // Obtiene el listado de roles para asignar al nuevo usuario
    const getRoles = async () => {
        await axios
            .get(`${baseUrl.url}usuario/roles`)
            .then((response) => {
                if (response.data.status === 200) {
                    setRoles(response.data.data);
                } else {
                    console.log(response.data.message);
                }
            })
            .catch((error) => console.log(error));
    };

    // Actualiza el estado que contiene la informacion del nuevo usuario 
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            [name]: value,
        }));
    };

    //Obtiene los canales que pertenecen al comercializador seleccionado 
    const handleChangeCM = (e) => {
        const { name, value } = e.target;
        const id = e.target.options[e.target.selectedIndex].getAttribute('data-id');
        setCMData((prevData) => ({
            ...prevData,
            [name]: value,
            [`id_${name}`]: id,
        }));
    };


    // Obtiene la lista de canales que pertenecen al cm seleccionado
    const getChbyCm = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}cm/ch`, {
                params: {
                    id_cm: cmData.id_cm
                }
            });

            if (response.data.status === 200) {
                setCanalList(response.data.data);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cmData.cm.trim() !== '') {
                    await getChbyCm();
                }
            } catch (error) {
                console.error("Error al obtener los canales segun cm:", error);
            }
        };

        fetchData();
    }, [cmData]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cmData.canal.trim() !== '' && cmData.cm.trim() !== '') {
                    await fetchDpByCM();
                }
            } catch (error) {
                console.error("Error al obtener los departamentos:", error);
            }
        };

        fetchData();
    }, [cmData]);

    // Obtiene el listado de departamentos que pertenecen al canal y cm seleccionados
    const fetchDpByCM = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}cm/depcm`, {
                params: {
                    cm: cmData.cm,
                    canal: cmData.canal
                }
            });

            if (response.data.status === 200) {
                setDpList(response.data.data);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Error in fetchDpByCM:", error);
        }
    };


    const handleChangeDoc = (e) => {
        const { name, value } = e.target;

        const parteNumericaActual = usuario.codVendedor.match(/\d+$/);

        const nuevoCodigoVendedor = parteNumericaActual
            ? usuario.codVendedor.replace(/\d+$/, value)
            : usuario.codVendedor + value;

        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            [name]: value,
            codVendedor: nuevoCodigoVendedor
        }));

    };


    // Actualiza la informacion del usuario al seleccionar un departamento
    const handleChangeDp = (e) => {
        const { value } = e.target;
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            idCanalComercializadorDepartamento: value,
        }));
    }

    const handleSelectRolChange = (e) => {
        const value = e.target.value;

        const codVendedorParteLiteral = value === '1' || value === '2' ? 'DEV' : 'V';

        const parteNumericaActual = usuario.codVendedor.match(/\d+$/);

        const nuevoCodigoVendedor = parteNumericaActual
            ? codVendedorParteLiteral + parteNumericaActual[0]
            : codVendedorParteLiteral;

        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            idRol: value,
            codVendedor: nuevoCodigoVendedor
        }));
    }

    useEffect(() => {
        FloatLabel.init();
        getRoles();
    }, [])

    return (
        <>

            <Modal show={show} className="custom-modal-width mt-4">
                <div style={{ maxWidth: "556px" }}>
                    <form onSubmit={handleSend}>
                        <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="ChangePasswordLabel">Agregar Usuario</h1>
                                </div>

                                <div className="modal-body">
                                    {
                                        currentStep === 1 && (
                                            <>
                                                <div className="float-container float-container-form">
                                                    <label htmlFor={"form-nombre"}>NOMBRE</label>
                                                    <input type="text" id="form-nombre" name="nombre" value={usuario.nombre} onChange={handleChange} data-placeholder="" autoComplete="off" required className="border-input form-control form-control-lg" />
                                                </div>
                                                <div className="float-container float-container-form">
                                                    <label htmlFor={"form-correo"}>CORREO</label>
                                                    <input type="text" id="form-correo" name="correo" value={usuario.correo} onChange={handleChange} data-placeholder="" autoComplete="off" required className="border-input form-control form-control-lg" />
                                                </div>
                                                <div className="float-container float-container-form">
                                                    <label htmlFor={"form-telefono"}>TELEFONO</label>
                                                    <input type="text" id="telefono" value={usuario.telefono} onChange={handleChange} name="telefono" data-placeholder="" autoComplete="off" required className="border-input form-control form-control-lg" />
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        currentStep === 2 && (
                                            <>
                                                <div className="float-container ">
                                                    <select name="form-rol" onChange={handleSelectRolChange} id="form-canal" className="border-input form-control form-control-lg">
                                                        <option value="0">Seleccione rol...</option>
                                                        {roles.map((rol) => (
                                                            <option key={rol.id} value={rol.id}>{rol.nombre}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="float-container float-container-form">
                                                    <input type="number" id="documento" name="documento" value={usuario.documento} onChange={handleChangeDoc} placeholder="DOCUMENTO" autoComplete="off" required className="border-input form-control form-control-lg" />
                                                </div>
                                                <div className="float-container">
                                                    <input type="text" id="form-codvendedor" value={usuario.codVendedor} name="form-codvendedor" onChange={handleChange} data-placeholder="" autoComplete="off" disabled className="border-input form-control form-control-lg" />
                                                </div>
                                                <div className="float-container float-container-form">
                                                    <input type="text" id="form-clave" name="form-clave" placeholder={usuario.clave} data-placeholder="" autoComplete="off" disabled className="border-input form-control form-control-lg" />
                                                </div>

                                            </>
                                        )

                                    }

                                    {
                                        currentStep === 3 && (
                                            <>
                                                <div className="float-container ">
                                                    <select name="cm" onChange={handleChangeCM} value={cmData.cm} id="form-cm" className="form-control form-control-lg">
                                                        {cm.map((cm) => (
                                                            <option key={cm.value} value={cm.label} data-id={cm.value} >{cm.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="float-container ">

                                                    {canalList.length > 0 ? (
                                                        <select name="canal" onChange={handleChangeCM} id="form-canal" value={cmData.canal} className="form-control form-control-lg">
                                                            <option key="0" value="0">Seleccione un canal...</option>
                                                            {canalList.map((ch) => (
                                                                <option key={ch.idCanal} value={ch.nombre} data-id={ch.idCanal}>{ch.nombre}</option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <hr />
                                                    )}

                                                </div>

                                                <div className="float-container ">

                                                    {
                                                        dpList.length > 0 ? (
                                                            <select name="cm" id="form-cm" onChange={handleChangeDp} className="form-control form-control-lg">
                                                                <option key="0" value="0">Seleccione un departamento</option>
                                                                {dpList.map((dp) => (
                                                                    <option key={dp.id_tbl_canal_cm_dep} value={dp.id_tbl_canal_cm_dep}>
                                                                        {dp.departamento}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <hr />
                                                        )
                                                    }


                                                </div>

                                            </>
                                        )
                                    }


                                </div>
                                <div id='alertDivChannel'></div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handlePrev}>{currentStep === 1 ? "Cancelar" : "Anterior"}</button>
                            <button id="btnSave" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleNext}>{currentStep === 3 ? "Guardar" : "Siguiente"}</button>
                        </Modal.Footer>
                    </form>
                </div>
            </Modal>
        </>
    );
}

export default FormUsuario;