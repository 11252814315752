import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";


async function setUpdateUser(dataParams) {

    const resp = await axios.put(`${baseUrl.url}usuario`, dataParams);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}

const FormUsuarioEdit = ({ data }) => {
    const [show] = useState(true);
    const [usuario, setUsuario] = useState(data); 
    const handleSend = async () => {
        const validate = await setUpdateUser({
            usuario
        });

        if (validate.status === 200) {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            reloadWindows();
        } else {
            
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }
    const handleClose = () => {
        window.location.reload();
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            [name]: value,
        }));
    };

    return (
        <Modal show={show} className="custom-modal-width mt-4">
            <div style={{ maxWidth: "556px" }}>
                <form onSubmit={handleSend}>
                    <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="ChangePasswordLabel">Editar Informacion Personal del Usuario</h1>
                            </div>
                            <div className="modal-body">
                                <div className="float-container float-container-form">
                                    <input type="text" id="form-nombre" name="nombre" value={usuario.nombre} placeholder="NOMBRE"  onChange={handleChange} autoComplete="off" required className="border-input form-control form-control-lg" />
                                </div>
                                <div className="float-container float-container-form">
                                    <input type="text" id="form-correo" name="correo" value={usuario.correo} placeholder="CORREO" onChange={handleChange} autoComplete="off" required className="border-input form-control form-control-lg" />
                                </div>
                                <div className="float-container float-container-form">
                                    <input type="text" id="telefono" value={usuario.telefono} name="telefono" placeholder="TELEFONO" onChange={handleChange} autoComplete="off" required className="border-input form-control form-control-lg" />
                                </div>
                            </div>
                            <div id='alertDivChannel'></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="text-center">
                        <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleClose}>Cancelar</button>
                        <button id="btnSave" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleSend}>Actualizar</button>
                    </Modal.Footer>
                </form>
            </div>
        </Modal>
    );
}

export default FormUsuarioEdit;