import PropTypes from 'prop-types'

function Header({ title, subT, subT2, color, idGestion }) {
  Header.defaultProps = {
    title: '-',
    subT: '-',
    subT2: '-',
    color: '',
    idGestion: '-',
  }

  Header.propTypes = {
    title: PropTypes.string.isRequired,
    subT: PropTypes.string.isRequired,
    subT2: PropTypes.string.isRequired,
    color: PropTypes.string,
    idGestion: PropTypes.string,
  }

  return (
    <div className='module-2 pt-2'>
      <div className='encabezado'>
        <div className='container'>
          <h1 className='animate__animated animate__backInUp negrita'>
            {title === '-' ? title.hide : title}
          </h1>
          <h3
            className='animate__animated animate__backInDown animate__delay-1s'
            style={{ color: color }}
          >
            {subT === '-' ? subT.hide : subT}
          </h3>
          <h3 className='animate__animated animate__backInDown animate__delay-1s'>
            {subT2 === '-' ? subT2.hide : subT2}
          </h3>
          <h5 className='animate__animated animate__backInDown animate__delay-1s'>
            {idGestion === '-' ? idGestion.hide : idGestion}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default Header
