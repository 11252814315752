import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../components/baseURL";
import Header from "../../components/Header";
import Table from 'react-bootstrap/Table';
import { BsFillPencilFill, BsFillTrashFill, BsPlusSquare, BsReplyFill } from "react-icons/bs";
import FormComercializador from "../../components/mantenimiento/comercializador/FormComercializador";
import FormComercializadorEdit from "../../components/mantenimiento/comercializador/FormComercializadorEdit";
import FormComercializadorDelete from "../../components/mantenimiento/comercializador/FormComercializadorDelete";

const PageHomeComercializador = () => {
  const [marketerList, setMarketerList] = useState([]);
  const [showModalComercializador, setModalComercializador] = useState([]);
  const [showModalCmEdit, setShowModalCmEdit] = useState([]);
  const [showModalCmDelete, setShowModalCmDelete] = useState([]); 

  // Solicitud AXIOS de Comercializadores
  const getMarketer = async () => {
    await axios
      .get(`${baseUrl.url}cm`)
      .then((response) => {
        if (response.data.status === 200) {
          setMarketerList(response.data.data)
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const showModal = () => setModalComercializador(<FormComercializador />);
  const showEditModal = (data) => setShowModalCmEdit(<FormComercializadorEdit data={data} />);
  const showDeleteModal = (data) => setShowModalCmDelete(<FormComercializadorDelete data={data} />);

  useEffect(() => {
    getMarketer();
  }, []);

  let showUser = false;
  if (JSON.parse(sessionStorage.getItem('dataUser')).roleUser !== "VENDEDOR") {
    showUser = true;
  }

  if (showUser) {
    return (
    <div>
      <Header title={"Mantenimiento de Comercializadores"} />
      <div className="container">
        <div className="row text-center mb-3">
          <div className="col-md-4 col-sm-12"><Link to={"/dashboard"}><label className="btn btn-secondary border-boton"><BsReplyFill /> VOLVER</label></Link></div>
          <div className="col-md-4 col-sm-12"><span><hr /></span></div>
          <div className="col-md-4 col-sm-12"><label className="btn btn-success border-boton" onClick={showModal}><BsPlusSquare /> AGREGAR</label></div>
        </div>
        <div className="row">
          <Table responsive striped bordered hover variant="light">
            <thead>
              <tr className="text-center">
                <th>NOMBRE</th>
                <th>DETALLE</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              {
                marketerList.map((data, i) => (
                  <tr key={i} className="text-center">
                    <td>{data.nombre}</td>
                    <td>{data.detalle}</td>
                    <td>
                      <label className="btn btn-warning" onClick={() => showEditModal(data)}><BsFillPencilFill /></label>
                      <span> | </span>
                      <label className="btn btn-danger" onClick={() => showDeleteModal(data)}><BsFillTrashFill /></label>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      </div>

      <div>
        {showModalComercializador}
      </div>
      <div>
        {showModalCmEdit}
        {showModalCmDelete}
      </div>

    </div>

  )
  } else {
    return (
      <>
        <div>
          <Header title={"Mantenimiento de Comercializadores"} />
        </div>

        <div className="text-center"> 
        <p>No tienes permiso para acceder a esta pagina </p>
        <Link to="/">
          <button className="btn_verde btn-lg btn-block border-boton mb-3"> Volver a inicio </button>
        </Link>
        
        </div>
      </>
    );
  }

  
}

export default PageHomeComercializador