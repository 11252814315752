import { useEffect, useState } from "react";
import { FloatLabel } from "../../js/inputs";
import axios from 'axios';
import { baseUrl } from '../baseURL';

// inicio el script para las animaciones de los input
function FormDetalleActividadActivacionesMensual() {

    const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
    const [codVendedor] = useState(sessionDate.codigoUser);
    const [tipoActivacion] = useState(1);
    const [activacionesCurrentMonth, setActivacionesCurrentMonth] = useState([]);
  
  useEffect(() => {
    FloatLabel.init();
    DetalleActivacionesCurrentMonth();
  },[]);

  // Solicitud AXIOS de Activaciones 1 Semana
  const DetalleActivacionesCurrentMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth/details`, {
        params: {
          tipo: tipoActivacion,
          codVendedor,
          efectiva: true
        }
      })
      .then((response) => {
        if(response.data.status === 200){
          setActivacionesCurrentMonth(response.data.data);
        }else{
            console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container h-100">
        <div className="row col-md-3 col-sm-12 mb-3"><a className="btn btn_verde btn-sm" href='/registeractivity'>Resumen</a></div>
        <div className="row">
        {
            activacionesCurrentMonth.map((data, i) => (
                <div key={i} className="card mb-4">
                    <div className="card-header">
                        {new Date(data.createdAt).toLocaleString('es-ES')}
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">{data.telefono}</li>
                        <li className="list-group-item">DUI: {data.num_documento}</li>
                        <li className="list-group-item">CDA: {data.cda}</li>
                    </ul>
                </div>
            ))
        }
        </div>
    </div>
  );
}

export default FormDetalleActividadActivacionesMensual;
