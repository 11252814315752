import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Col } from "react-bootstrap";
import { crearPdfHome } from "../../services/crearPDFHome.js";
import { consultaEquifax } from "../../services/ConsultaEquifax.js";
import { Clausulas } from "./helpers/Clausulas.jsx";
import axios from "axios";
import loading from "../../img/Loading.gif";
import { baseUrl } from '../../components/baseURL.jsx';

function FormConsultaHomeFirma({ step, setStep }) {
    const data = JSON.parse(sessionStorage.getItem("data_perfilamiento")) || {};
    const user = JSON.parse(sessionStorage.getItem("dataUser")) || {};
    const codVendedor = sessionStorage.getItem("codVendedor");
    const [errors, setErrors] = useState([]);
    const signatureRef = useRef(null);
    const [error, setError] = useState(false);
    const title = Clausulas[0].title;

    const cancel = () => {
        setStep(0);
    }

    const isLoading = (status) => {
        document.getElementById("btn-cancel").hidden = status;
        document.getElementById("btn-save").hidden = status;
        document.getElementById("btn-retry").hidden = status;
        document.getElementById("loading").hidden = !status;
    }

    const saveSignature = () => {
        if (!signatureRef.current.isEmpty()) {
            const canvas = signatureRef.current.getCanvas();
            const imageDataUrl = canvas.toDataURL("image/png");
            return imageDataUrl;
        }
        setError(true);
        return null;
    };

    const getPreApprovedAmounts = async (puntaje) => {
        const response = await axios.get(`${baseUrl.url}get-offers?puntaje=${puntaje}`);
        return response.data;
    }

    const getDefaultAmount = async () => {
        const response = await axios.get(`${baseUrl.url}get-default-offer`);
        return response.data;
    }

    const saveBuroDataOnSuccess = async (datos) => {
        try {
            const response = await axios.post(`${baseUrl.url}create-buro`, datos);
            if (response.status === 201) {
                return response;
            }
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    const saveBuroDataOnError = async (datos) => {
        try {
            const response = await axios.post(`${baseUrl.url}create-buro-error`, datos);
            if (response.status === 201) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    const updateTrxPerfilador = async (datos) => {
        try {
            const response = await axios.post(`${baseUrl.url}update-perfil`, datos);
            if (response.status === 201) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    const getImage = async () => {
        try {
            isLoading(true);

            const imageDataUrl = saveSignature();

            if (!imageDataUrl) {
                isLoading(false);
                return;
            }

            const canvasWidth = 350;
            const canvasHeight = 250;
            const image = new Image();
            image.src = imageDataUrl;

            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });

            const canvas = document.createElement("canvas");
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            const finalImageDataUrl = canvas.toDataURL("image/png");

            const objeto = { imagen: finalImageDataUrl, nombres: data.nombres, num_doc: data.num_doc };

            const datosCodificado = encodeURIComponent(JSON.stringify(objeto));

            const { channelUser: canal, cmUser: comercializador } = user;
            const { num_doc: documento, id_trx: id_trx } = data;

            console.log("Iniciando proceso de perfilamiento para el documento: " + documento); 

            const responsePDF = await crearPdfHome(datosCodificado);

            if (responsePDF.status !== 200) {
                console.log("Ocurrió un error al guardar el PDF de la autorizacion para consulta buro para el documento: "+ documento);
                return;
            }

            sessionStorage.setItem("tempPDF", responsePDF.pdf);

            try {

                const consulta = await consultaEquifax({ documento });

                /* Equifax respondio, pero ocurrio un error en los pasos  */
                if (consulta && consulta.status !== 200) {
                    console.log("No se puedo obtener un puntaje para el documento:" + documento); 
                    const { mensaje, codigo, endpoint } = consulta.data;
                    const datos = { mensaje, codigo, endpoint, documento, codVendedor, canal, comercializador };
                    const trxDatos = { id_trx, finalizado: false, paso: endpoint };
                    await saveBuroDataOnError(datos);
                    await updateTrxPerfilador(trxDatos);
                    const montos = await getDefaultAmount();
                    sessionStorage.setItem("montos", JSON.stringify(montos.data));
                    window.location.href = "/consultahome/oferta";
                    return;
                }

                console.log("Se obtuvo un puntaje para el documento: " + documento); 

                const { score, puntaje, fechaData } = consulta.data;
                const datos = { documento, score, puntaje, fechaData, codVendedor, canal, comercializador };
                const trxDatos = { id_trx, finalizado: true, paso: "final" };

                await saveBuroDataOnSuccess(datos);
                await updateTrxPerfilador(trxDatos);

                if (puntaje > 0) {
                    const montos = await getPreApprovedAmounts(puntaje);

                    if (montos && montos.status === 200) {
                        sessionStorage.setItem("montos", JSON.stringify(montos.data));
                        window.location.href = "/consultahome/oferta";
                    }
                    return;
                }

                const montos = await getDefaultAmount();

                if (montos && montos.status === 200) {
                    sessionStorage.setItem("montos", JSON.stringify(montos.data));
                    window.location.href = "/consultahome/oferta";
                }

                isLoading(false);

            } catch (error) {
                console.log("Ocurrio un error en la consulta al buro para el documento: " + documento);
                const mensaje = "Equifax no respondio";
                const codigo = 500;
                const endpoint = "Internal Server Error";
                const datos = { mensaje, codigo, endpoint, documento, codVendedor, canal, comercializador };
                const trxDatos = { id_trx, finalizado: false, paso: endpoint };
                await saveBuroDataOnError(datos);
                await updateTrxPerfilador(trxDatos);
                const montos = await getDefaultAmount();
                sessionStorage.setItem("montos", JSON.stringify(montos.data));
                window.location.href = "/consultahome/oferta";
            }

        } catch (error) {
            isLoading(false);
            setErrors("Ocurrio un error en el proceso, reintenta nuevamente");
            console.log(error);
        }
    }

    return (
        <>
            <Col xs={12} sm={12} md={9} lg={5} xl={5} className="d-flex align-items-center m-auto px-2">
                <h3 className="text-center">
                    {step === 2 ? title : ""}
                </h3>
            </Col>

            <div className="contenedor-firma">
                <SignatureCanvas
                    canvasProps={{
                        width: 350,
                        height: 250,
                        className: "firma",
                    }}
                    ref={signatureRef}
                    onBegin={() => {
                        setError(false);
                    }}
                />
                <p className="text-center">{errors}</p>
                {error && <div className="message-error">La firma es obligatoria</div>}
                <button
                    className="btn-blanco-azul mt-3 mb-3"
                    id="btn-retry"
                    onClick={() => {
                        if (signatureRef.current) {
                            signatureRef.current.clear();
                            setError(false);
                        }
                    }}
                >
                    Reintentar
                </button>

                <Col xs={12} sm={12} md={9} lg={5} xl={5} className="d-flex justify-content-between m-auto px-2">
                    <button id="btn-cancel" onClick={cancel} className="btn_verde mb-5">
                        Cancelar
                    </button>

                    <button onClick={getImage} id="btn-save" className="btn_verde mb-5">
                        Continuar
                    </button>
                </Col>

                <img src={loading} alt="validando-firma" id="loading" hidden />
            </div>
        </>
    );
}

export default FormConsultaHomeFirma; 