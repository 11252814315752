import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { baseUrl } from "../components/baseURL";
import { FloatLabel } from "../js/inputs";


// Consulat AXIOS para Logueo
async function loginUser(credentials) {
  const resp = await axios.post(`${baseUrl.url}login`, credentials);
  
  return resp.data;
}

export default function PageLogin({ setToken }) {
  useEffect(() => {
    FloatLabel.init();
  });

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

//   const handleSubmit2 = async (e) => {
//     e.preventDefault();
//     alert("ACCESO SERA HABILITADO EN BREVE...");
//   };

// Envio AXIOS Formulario de Logueo
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const validate = await loginUser({
      username,
      password,
    });

    if(validate.status === 200){
      sessionStorage.setItem('codVendedor', validate.data.codigo)
      setToken(validate);
    }else {
      document.getElementById('alertDanger').innerHTML = '<div class="alert alert-danger text-center" role="alert">'+validate.message+'</div>';
    }
  };

  return (
    <section className="container py-5 h-100 mb-5">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">
          <div className="card shadow-2-strong">
            <div className="card-body text-center">
              <form onSubmit={handleSubmit}>
                <div className="form mb-4 float-container float-container-form">
                  <label htmlFor={"form-username"}>Usuario</label>
                  <input
                    type="text"
                    id="form-username"
                    name="form-username"
                    pattern="[A-Za-z0-9]+"
                    title="Sólo letras y números, máximo 15 caracteres"
                    maxLength={15}
                    data-placeholder=""
                    autoComplete="off"
                    className="border-input form-control form-control-lg"
                    onChange={(e) => setUserName(e.target.value.toUpperCase())}
                  />
                </div>

                <div className="form mb-4 float-container float-container-form">
                  <label htmlFor={"form-password"}>Contraseña</label>
                  <input
                    type="password"
                    id="form-password"
                    name="form-password"
                    data-placeholder=""
                    autoComplete="off"
                    className="border-input form-control form-control-lg"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div id="alertDanger"></div>

                <button
                  className="btn btn-primary btn-lg btn-block border-boton mb-3"
                  type="submit"
                >
                  Iniciar Sesión
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PageLogin.propTypes = {
  setToken: PropTypes.func.isRequired,
};
