import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { obtenerOcr } from '../../services/ObtenerOcrServices'
import loading from '../../img/Loading.gif'
import { activarLinea } from '../../services/ActivacionLineaServices'
import { nuevaPortabilidad } from '../../services/NuevaPortabilidadServices'
import {
  CreateDataActivacionPorta,
  CreateDataSolicitud,
  obtenerIdGestion,
} from '../../services/DbServices'
import { obtenreInfoCliente } from '../../services/PerfilamientoServices'
import { envioDatosBot } from '../../services/EnvioDatosBot'

function PageFinalizacionPortabilidad() {
  const [dui, setDui] = useState('')
  const [nombre, setNombre] = useState('')
  const [fecha, setFecha] = useState('')
  const [direccion, setDireccion] = useState('')
  const [idGestion, setIdGestion] = useState()

  obtenerOcr(sessionStorage.getItem('uid')).then((response) => {
    setNombre(response['visualZone']['fullName']['value'])
    if (response['visualZone']['addressStreet'] !== null) {
      setDireccion(response['visualZone']['addressStreet']['value'])
    }
    var fa = ''
    if (response['visualZone']['dob'] !== null) {
      fa = response['visualZone']['dob']['value']
    } else if (response['mrz']['dob'] !== null) {
      fa = response['mrz']['dob']['value']
    }
    const f = new Date(fa)
    var dia = f.getDate()
    var mes = f.getMonth() + 1 // Los meses en JavaScript se cuentan desde 0
    var anio = f.getFullYear()

    const duiSinGuion = response['visualZone']['documentNumber']['value']
    var ultimoNumero = duiSinGuion.slice(-1)
    var duiGuion = duiSinGuion.slice(0, -1) + '-' + ultimoNumero

    setDui(duiGuion)

    setFecha(dia + '/' + mes + '/' + anio)
  })

  // Id de gestión
  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  const [activacion, setActivacion] = useState('')
  const [validarCreacionPortabilidad, setValidarCreacionPortabilidad] =
    useState(true)
  const [messageError, setMessageError] = useState('')
  const [telefono, setTelefono] = useState('')

  useEffect(() => {
    //Datos quemados temporalmente
    if (fecha !== '' && activacion === '') {
      const datos = {
        optionOperation: 1,
        customerName: nombre,
        customerNumber: sessionStorage.getItem('cda'),
        numberToKeep: 0,
        documentType: 'DUI',
        documentNumber: dui,
        address: direccion,
        dateOfBirth: fecha,
        vendorCode: '38529',
        userCode: 'ACTIVACIONES_WAGC',
        frecuentNumbers: '00000000|00000000',
      }
      activarLinea(encodeURIComponent(JSON.stringify(datos))).then(
        async (response) => {
          const data = {
            paso: 7,
            telefono: response['description'], //Número temporal asignado luego de activar el cda
            sales_order: response['transactionNumber'],
            trx: sessionStorage.getItem('trx'),
          }
          const datosCodificado = encodeURIComponent(JSON.stringify(data))
          CreateDataActivacionPorta(datosCodificado).then((response) => {})

          const duiSinGuion = sessionStorage.getItem('dui')
          var ultimoNumero = duiSinGuion.slice(-1)
          var duiGuion = duiSinGuion.slice(0, -1) + '-' + ultimoNumero

          if (response !== null && validarCreacionPortabilidad) {
            setValidarCreacionPortabilidad(false)
            sessionStorage.setItem('temporalMsisdn', response['description'])
            setTelefono(response['description'])
            var sales_order = response['transactionNumber']
            var network = 0

            if (sessionStorage.getItem('compania') === 'claro') {
              network = 11
            }
            if (sessionStorage.getItem('compania') === 'tigo') {
              network = 21
            }
            if (sessionStorage.getItem('compania') === 'digicel') {
              network = 12
            }

            obtenreInfoCliente(response['descripcion']).then(
              async (response) => {
                var custumerId = ''
                if (
                  response['status'] === 'OK' &&
                  response['tipoCliente'] === 'PREPAGO'
                ) {
                  custumerId = response['swCustomerId']
                } else {
                  custumerId = '16255288'
                }
                const datos = {
                  firstName: sessionStorage.getItem('nombre'),
                  lastName: sessionStorage.getItem('apellido'),
                  individualIdentification: duiGuion,
                  individualIdentificationType: 'DUI',
                  fullAddress: sessionStorage.getItem('direccion'),
                  msisdn: sessionStorage.getItem('telefono'),
                  routingNetwork: network,
                  temporalMsisdn: sessionStorage.getItem('temporalMsisdn'),
                  accountTypeCustomerAccount: 'PRE',
                  nip: sessionStorage.getItem('cip'),
                  locations: '/Portacionespdf',
                  documentType: 'SP',
                  name: '50a87cfc2b4847eeaea036804aa99cdc.pdf',
                  description: 'Submit Port In Request',
                  sellerID: '25289',
                  swCustomerId: custumerId,
                  partialPorting: 'true',
                  donorOperator: sessionStorage.getItem('compania'),
                  receptorOperator: 'telefonica',
                  technology: 'MO',
                  donorAccountType: 'PRE',
                }
                const datosCodificado = encodeURIComponent(
                  JSON.stringify(datos)
                )

                nuevaPortabilidad(datosCodificado).then((response) => {
                  const data = {
                    paso: 8,
                    telefono: telefono, // Numero temporal que se asigna luego de la activación del CDA
                    sales_order: sales_order,
                    pdf: sessionStorage.getItem('pdf'),
                    finalizado: true,
                    trx: sessionStorage.getItem('trx'),
                    tipo: 2,
                    cda: sessionStorage.getItem('cda'),
                    codVendedor: sessionStorage.getItem('codVendedor'),
                    numero_portar: sessionStorage.getItem('telefono'), //Número de telefono a portar ingresado desde el formulario
                    request_id_spn: response['request_id_spn'],
                  }
                  const dataCodificada = encodeURIComponent(
                    JSON.stringify(data)
                  )
                  CreateDataSolicitud(dataCodificada).then((response) => {
                    envioDatosBot(data).then((response) => {
                      console.log(response)
                      if (response.status === 1) {
                        console.log(response.mensaje)
                      }
                    })
                  })

                  setActivacion(response['status'])
                  setMessageError(response['error'])
                })
                console.log(activacion)
              }
            )
          }
        }
      )
    }
  }, [
    dui,
    nombre,
    fecha,
    direccion,
    telefono,
    activacion,
    validarCreacionPortabilidad,
  ])

  console.log(activacion)
  if (activacion === '') {
    return (
      <div className='contenedor-informacion contenedor-gif'>
        <img
          src={loading}
          alt='validando-cda'
          id='loading'
          style={{ width: '100px' }}
        />
      </div>
    )
  }

  if (activacion === 500) {
    return (
      <div>
        <Header
          subT={'No podemos procesar la solicitud'}
          idGestion={`Id gestión: ${idGestion}`}
        />
        <div className='contenedor-informacion'>
          <div className='cuadro-informacion'>
            <p className='message-error-label'>{messageError}</p>
          </div>
          <div className='contenedor-botones'>
            <Link
              to={'/'}
              type='submit'
              className='btn-azul-oscuro btn-space mb-3'
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header
        subT={'¡Solicitud de portación exitosa!'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <div className='contenedor-informacion'>
        <div className='cuadro-informacion'>
          <p>
            El número CDA {sessionStorage.getItem('cda')} fue activado
            correctamente con el número temporal{' '}
            <span color='--color-blue-movistar'>{telefono}</span>
          </p>
        </div>
        <br />
        <div className='cuadro-informacion'>
          <p>
            Se ha ingresado la solicitud de portación, el cliente disfrutará del
            servicio en el número temporal en lo que se completa el proceso.
          </p>
        </div>
        <div className='contenedor-info-extra'>
          <p>
            La portabilidad de la línea se completará en un máximo de
            <b> 3 días hábiles. </b> Al finalizar el proceso, el cliente
            recibirá un mensaje de texto notificando la portación exitosa.
          </p>
        </div>

        <div className='contenedor-botones'>
          <Link
            to={'/'}
            type='submit'
            className='btn-azul-oscuro  btn-space mb-2'
          >
            Aceptar
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageFinalizacionPortabilidad
