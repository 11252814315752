import { Col } from "react-bootstrap";
import "./style.css";
import { Clausulas } from "./helpers/Clausulas.jsx";

function FormConsultaHomeClausula({ step, setStep }) {
    // const num_clausula = step === 1 ? 0 : step === 3 ? 1 : 2;
    const clausula = Clausulas[0];

    const handleSigned = () => {
        setStep(step + 1);
    }

    const cancel = () => {
        setStep(0);
    }

    return (
        <>
            <Col xs={12} sm={12} md={9} lg={5} xl={5} className="d-flex align-items-center m-auto px-2">
                <h3 className="text-center">
                    {clausula.title}
                </h3>
            </Col>

            <div id="terminos-container">
                <p dangerouslySetInnerHTML={{ __html: clausula.message }} />
            </div>

            <div id="botones-container">
                <button className="btn btn-sm btn_verde" onClick={cancel} >No Firmar</button>
                <button className="btn btn-sm btn_verde" onClick={handleSigned} >
                {/* {step === 3 ? "Continuar" : "Firmar"}    */} 
                Firmar
                </button>
            </div>
        </>
    );
}

export default FormConsultaHomeClausula; 