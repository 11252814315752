import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function envioDatosBot(datos) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrl.url}send-bot`, datos)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}