import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";

async function setDeleteMarketer(id) {

    const resp = await axios.delete(`${baseUrl.url}cm/${id}`);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}

const FormComercializadorDelete = ({ data }) => {
    const [show] = useState(true);
    const [id, setId] = useState(data.id || '');
    const handleClose = () => window.location.reload();

    const handleSend = async e => {
        e.preventDefault();

        const validate = await setDeleteMarketer(id);

        if (validate.status === 200) {
            document.getElementById("closed").style.display = "none";
            document.getElementById("delete").style.display = "none";
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            reloadWindows();
        } else {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }

    useEffect(() => {
        FloatLabel.init();
      },[])


    return (
        <>
            <Modal show={show} className="custom-modal-width mt-4">
                <div style={{ maxWidth: "556px" }}>
                    <form onSubmit={handleSend}>
                        <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="ChangePasswordLabel">Inactivar Comercializador</h1>
                                </div>

                                <div className="modal-body">
                                    <p>¿Estas Seguro Que Deseas Inactivar Este Registro?</p>
                                </div>
                                <div id='alertDivChannel'></div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <button id="closed" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleClose}>Salir</button>
                            <button id="delete" className="btn_verde btn-lg btn-block border-boton mb-3" type="submit">Inactivar</button>
                        </Modal.Footer>
                    </form>
                </div>
            </Modal>

        </>
    );

}

export default FormComercializadorDelete