import Header from '../../components/Header'
import FormDetalleActividadPortaEfectivaMensual from '../../components/registroActividad/FormDetalleActividadPortaEfectivaMensual'

const PageDetalleActividadPortaEfectivaMensual = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Portaciones Efectivas del Mes en la Web'}
      />
      <FormDetalleActividadPortaEfectivaMensual />
    </div>
  )
}

export default PageDetalleActividadPortaEfectivaMensual
