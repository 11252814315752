import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function verificarCompania(telefono) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl.url}verificar-compania/${telefono}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
