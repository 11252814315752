import { useEffect, useState } from "react";
import { FloatLabel } from "../../js/inputs";
import axios from 'axios';
import { baseUrl } from '../baseURL';
import { useNavigate } from "react-router-dom";
import { crearNip } from "../../services/NipServices";
import imgLoading from "../../img/Loading.gif";
import db from "../../helpers/indexedDBHelper";

// inicio el script para las animaciones de los input
function FormDetalleActividadPortaNoEfectivaMensual() {

  const navigate = useNavigate();
  const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
  const [codVendedor] = useState(sessionDate.codigoUser);
  const [tipoActivacion] = useState(2);
  const [portaNoEfectivaCurrentMonth, setPortaNoEfectivaCurrentMonth] = useState([]);
  const [nip, setNip] = useState(sessionStorage.getItem("nip"));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    FloatLabel.init();
    DetallePortaNoEfectivaCurrentMonth();
  }, []);

  // Solicitud AXIOS de Activaciones 1 Semana
  const DetallePortaNoEfectivaCurrentMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth/porta`, {
        params: {
          codVendedor,
        }
      })
      .then((response) => {
        if (response.data.status === 200) {

          generarDataUpdate(response.data.data)
            .then((dataUpdateFiltered) => {
              setPortaNoEfectivaCurrentMonth(dataUpdateFiltered.filter(item => item.estado !== 'FINALIZADO_AP'));
              setIsLoading(false);
            })
            .catch((error) => {
              console.error('Error al generar dataUpdate:', error);
            });

        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const generarDataUpdate = async (data) => {
    try {
      const tokens = await db.tokens.toArray();
      const dataUpdateArray = await Promise.all(
        data.map(async (item) => {
          const telefono = item.telefono;
          const tokenExists = tokens.find(token => token.telefono === telefono);

          if (tokenExists) {
            try {
              const estado = await getCheckResponse(tokenExists.token);
              return { ...item, estado };
            } catch (error) {
              console.error('Error al obtener el estado de portabilidad:', error);
              return null;
            }
          } else {
            return { ...item, estado: 'No encontrado' };
          }
        })
      );

      return dataUpdateArray.filter(Boolean);
    } catch (error) {
      console.error('Error al generar los datos:', error);
      return null;
    }
  };

  // Validacion Estado Porta TELCASV
  const getCheckResponse = async (token) => {
    try {
      const response = await
        axios({
          method: 'post',
          url: `${baseUrl.url}get-check-response`,
          params: {
            "tipo": "CALL_API_MOVISTAR_DATABASE",
            "token": token,
          }
        });

      return response.data.estado;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  const navigationRetry = async (dataUser) => {
    sessionStorage.setItem('portaRetry', JSON.stringify(dataUser));
    if (nip === "" || nip === undefined) {
      console.log("Creacion de nip")
      crearNip(dataUser.numeroportar).then((response) => {
        console.log(response);
        setNip(response["idNip"]);
        sessionStorage.setItem("nip", response['idNip'])
        console.log("Solicitud de nip creada")
      });
      navigate("/detailactivity/portability/re-entry");
    } else {
      sessionStorage.setItem("nip", '')
    }
  };

  return (
    <div className="container h-100">
      <div className="row col-md-3 col-sm-12 mb-3"><a className="btn btn_verde btn-sm" href='/registeractivity'>Resumen</a></div>
      <div className="row">
        {
          isLoading ?
            (
              <div className='contenedor-informacion contenedor-gif'>
                <img
                  src={imgLoading}
                  alt='consultando-estado'
                  id='loading'
                  style={{ width: '100px' }}
                />
              </div>
            ) :
            (
              portaNoEfectivaCurrentMonth.map((data, i) => (
                <div key={i} className="card mb-4">
                  <div className="card-header">
                    {new Date(data.createdAt).toLocaleString('es-ES')}
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">{data.numeroportar}</li>
                    <li className="list-group-item">DUI: {data.num_documento}</li>
                    <li className="list-group-item">CDA: {data.cda}</li>
                    <li className="list-group-item">Temporal: {data.telefono}</li>
                    <li className="list-group-item">
                    Estado:&nbsp;
                    {data.estado === 'FINALIZADO_AP' ? (
                      'Finalizada'
                    ) : (
                      <>
                        Rechazada{' '}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target={"#exampleModal" + i}
                          data-bs-whatever="@mdo"
                        >
                          Reingreso
                        </button>
                      </>
                    )}  
                    </li>
                  </ul>

                  <div className="modal fade" id={"exampleModal" + i} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">Solicitud de CIP</h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <h5>¿Estas Seguro que Deseas Solicitar Nuevo CIP para el Número {data.numeroportar}?</h5>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={e => navigationRetry(data)}>Solicitar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )
        }
      </div>
    </div>
  );
}

export default FormDetalleActividadPortaNoEfectivaMensual;
