import { Link } from "react-router-dom";
import logo from "../img/logo-@x2.png";
import logoM from "../img/logom-@x2.png";

function NavbarLogin() {
  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link
          className="navbar-brand"
          to="/"
          target="_self"
        >
          <img src={logo} alt="Logo Movistar" className="d-none d-md-block" width={163} height={35} />
          <img src={logoM} alt="Logo M" className="d-block d-md-none" width={65} height={51} />
        </Link>
        <h3 className="name-project m-0">Portal de Ventas</h3>
      </div>
    </div>
  );
}

export default NavbarLogin;