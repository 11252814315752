import Header from '../../components/Header'
import FormDetalleActividadPortaNoEfectivaMensual from '../../components/registroActividad/FormDetalleActividadPortaNoEfectivaMensual'

const PageDetalleActividadPortaNoEfectivaMensual = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Portaciones No Efectivas Mensual en la Web'}
      />
      <FormDetalleActividadPortaNoEfectivaMensual />
    </div>
  )
}

export default PageDetalleActividadPortaNoEfectivaMensual
