import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { baseUrl } from '../components/baseURL'
import {FloatLabel} from "../js/inputs";
import { Link } from "react-router-dom";
import logo from "../img/logo-@x2.png";
import logoM from "../img/logom-@x2.png";
import db from "../helpers/indexedDBHelper"; 

// Cerrar Sesión
const tokenDestroy = async () => {
  sessionStorage.removeItem('dataUser');
  sessionStorage.removeItem('portaRetry');
  sessionStorage.removeItem('codVendedor');
  sessionStorage.removeItem('nip');
  await db.open();
  await db.delete();
  window.location.reload(); 
};

// AXIOS Cambiar Password
async function resetPassword(credentials) {

  const resp = await axios.post(`${baseUrl.url}usuario/password`, credentials);

  return resp.data
}

// AXIOS Cambiar DEP y PDV
async function setCreateUserLogin(dataParams) {

  console.log(dataParams);

  const resp = await axios.post(`${baseUrl.url}usuario/loginuser`, dataParams);

  return resp.data
}

// Limpiar Input Modal Cambio Contraseña
const clearInput = () => {
  document.getElementById('form-password').value = '';
  document.getElementById('form-repeatpassword').value = '';
};

// Limpiar Mensaje Modal Cambio Contraseña
const clearMessage = () => {
  document.getElementById('alertDiv').innerHTML = '';
};

export default function Navbar() {

  const [optionDepList, setOptionDepList] = useState([]);
  const [optionPDVList, setOptionPDVList] = useState([]);

  const [idCanalComercializadorDepartamento, setidCanalComercializadorDepartamento] = useState();
  const [idCanalCmDepartamentoPv, setidCanalCmDepartamentoPv] = useState();

  const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
  const [codVendedor] = useState(sessionDate.codigoUser);
  const [password, setPassword] = useState();
  const [repeatpassword, setRepeatPassword] = useState();

  let UserName = "";

  useEffect(() => {
    FloatLabel.init();
    fetchData(sessionDate.cmUser);
  },[])

  
  
  if (sessionDate.username === "DUMMY") {
    UserName = "Estimado Colaborador";
  }else{
    UserName = sessionDate.username;
  }

  // Envio Formulario Cambio Contraseña
  const handleChangePasswordSubmit = async e => {
    e.preventDefault();
    
    const validate = await resetPassword({
        codVendedor,
        password,
        repeatpassword
    });

    clearInput();
  
    if(validate.status === 400){
      document.getElementById('alertDiv').innerHTML = '<div class="alert alert-danger text-center" role="alert">'+validate.message+'</div>';
    }else if (validate.status === 200){
      document.getElementById('alertDiv').innerHTML = '<div class="alert alert-success text-center" role="alert">'+validate.message+'</div>';
      setInterval("location.reload()",3000);
    }
  }

  // Envio Formulario Cambio DEP y PDV
  const handleChangePDV = async e => {
    e.preventDefault();
    
    const validate = await setCreateUserLogin({
        codVendedor,
        idCanalComercializadorDepartamento,
        idCanalCmDepartamentoPv
    });
  
    if(validate.status === 201){
      document.getElementById('alertDivCMDep').innerHTML = '<div class="alert alert-success text-center" role="alert">'+validate.message+'</div>';
      setInterval("location.reload()",3000);
    } else {
      document.getElementById('alertDivCMDep').innerHTML = '<div class="alert alert-danger text-center" role="alert">'+validate.message+'</div>';
    }
  }

  // Solicitud AXIOS de Departamentos Según CM
  const fetchData = (cmName) => {
    axios
      .get('https://api-ventas.ivy-movistar.cloud/cm/depcm', {
        params: {
          cm: sessionDate.cmUser,
          canal: sessionDate.channelUser
        }
      })
      .then((response) => {
        if(response.data.status === 200){
          setOptionDepList(response.data.data)
        }else{
            console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // Solicitud AXIOS de PDV Según DEP
  const fetchDataPDV = (value) => {
    setidCanalComercializadorDepartamento(value);
    axios
      .get('https://api-ventas.ivy-movistar.cloud/cm/pdvdepcm', {
        params: {
          id_tbl_canal_cm_dep: value
        }
      })
      .then((response) => {
        if(response.data.status === 200){
          if (response.data.data.length >= 1){
            setOptionPDVList(response.data.data)
            document.getElementById("pdv").hidden = false
          }else {
            document.getElementById("pdv").hidden = true
          }
        }else{
            console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link
          className="navbar-brand"
          to="/"
          target="_self"
        >
          <img src={logo} alt="Logo Movistar" className="d-none d-md-block" width={163} height={35} />
          <img src={logoM} alt="Logo M" className="d-block d-md-none" width={65} height={51} />
        </Link>
        <h3 className="name-project m-0">Portal de Ventas</h3>
        <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-3 text-center">
          <span className="username">Bienvenido <br/>{UserName}</span>
          <div className="dropdown">
            <button className="btn_verde dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Menú
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              {/* <li><a className="dropdown-item" href='/registeractivity' data-bs-target="#registroActividad">Registro de Actividad</a></li>
              <li><a className="dropdown-item" href='/' data-bs-toggle="modal" data-bs-target="#ChangePassword">Cambiar Contraseña</a></li>
              <li><a className="dropdown-item" href='/' data-bs-toggle="modal" data-bs-target="#ChangePDV">Cambiar Punto de Venta</a></li>
              <li><hr className="dropdown-divider"/></li> */}
              <li><a className="dropdown-item" href='/' onClick={tokenDestroy}>Cerrar Sesión</a></li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="modal fade" id="ChangePassword" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="ChangePasswordLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="ChangePasswordLabel">Cambiar Contraseña</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleChangePasswordSubmit}>
              <div className="modal-body">
                <div className="form mb-4 float-container float-container-form">
                    <label htmlFor={"form-codVendedor"}>Usuario</label>
                    <input type="text" id="form-codVendedor" name="form-codVendedor" data-placeholder="" autoComplete="off" value={sessionDate.codigoUser + ' - ' + UserName} className="border-input form-control form-control-lg" readOnly/>
                </div>
                <div className="form mb-4 float-container float-container-form">
                    <label htmlFor={"form-password"}>Contraseña</label>
                    <input type="password" id="form-password" name="form-password" data-placeholder="" autoComplete="off" className="border-input form-control form-control-lg" required onChange={e => setPassword(e.target.value)}  onFocus={clearMessage}/>
                </div>
                <div className="form mb-4 float-container float-container-form">
                    <label htmlFor={"form-repeatpassword"}>Repetir Contraseña</label>
                    <input type="password" id="form-repeatpassword" name="form-repeatpassword" data-placeholder="" autoComplete="off" className="border-input form-control form-control-lg" required onChange={e => setRepeatPassword(e.target.value)}/>
                </div>
                <div className="float-container text-center">
                  <button className="btn_verde btn-lg btn-block border-boton mb-3" type="submit">Cambiar Contraseña</button>
                </div>
              </div>
              <div id='alertDiv'></div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ChangePDV" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="ChangePDVLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="ChangePDVLabel">Cambiar Punto de Venta</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleChangePDV}>
              <div className="modal-body">
                <div className="float-container float-container-form">
                    <label htmlFor={"form-cmCanal"}>CM - CANAL</label>
                    <input type="text" id="form-cmCanal" name="form-cmCanal" data-placeholder="" autoComplete="off" value={sessionDate.cmUser + ' - ' + sessionDate.channelUser} className="border-input form-control form-control-lg" readOnly/>
                </div>
                <div className='float-container'>
                  <select className="form-select form-select-lg mb-3" defaultValue="" onChange={(e) => fetchDataPDV(e.currentTarget.value)} name="deparamento" id="departamento" aria-label=".form-select-lg example">
                    <option value="">Seleccione Departamento</option>
                    {
                      optionDepList.map((data, i) => (
                        <option key={i} value={data.id_tbl_canal_cm_dep}>{data.departamento}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='float-container'>
                  <select className="form-select form-select-lg mb-3" defaultValue="" name="pdv" id="pdv" onChange={e => setidCanalCmDepartamentoPv(e.target.value)} aria-label=".form-select-lg example">
                    <option value="">Seleccione PDV</option>
                    {
                      optionPDVList.map((data, i) => (
                        <option key={i} value={data.id_tbl_canal_cm_dep_pdv}>{data.pdv}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="float-container text-center">
                  <button className="btn_verde btn-lg btn-block border-boton mb-3" type="submit">Guardar Cambios</button>
                </div>
              </div>
              <div id='alertDivCMDep'></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}