import React from "react";
import { Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar";
import NavbarLogin from "./components/NavbarLogin";

import PageLogin from "./pages/PageLogin";
import useToken from "./js/useToken";

import PageMenu from "./pages/PageMenu";

import PagePortabilidad from "./pages/portabilidad/PagePortabilidad";
import PageValidacionPortabiidad from "./pages/portabilidad/PageValidacionPortabilidad";
import PageFinalizacionPortabillidad from "./pages/portabilidad/PageFinalizacionPortabilidad";

import PageValidacionActivacion from "./pages/activacion/PageValidacionActivacion";
import PageFinalizacionActivacion from "./pages/activacion/PageFinalizacionActivacion";
import PageErrorActivacion from "./pages/activacion/PageErrorActivacion";

import PageRecuperacion from "./pages/recuperacion/PageRecuperacion";
import PageFinalizacionRecuperacion from "./pages/recuperacion/PageFinalizacionRecuperacion";
import PageErrorRecuperacion from "./pages/recuperacion/PageErrorRecuperacion";

import PageErrorVerificacion from "./pages/verificacion/PageErrorVerificacion";

import PageFirma from "./pages/portabilidad/PageFirma";
import PageHomeDashboard from "./pages/mantenimiento/pageHomeDashboard";
import PageHomeUsuario from "./pages/mantenimiento/pageHomeUsuario";
import PageHomeComercializador from "./pages/mantenimiento/pageHomeComercializador";
import PageHomeCanal from "./pages/mantenimiento/pageHomeCanal";
import PageHomeActivacion from "./pages/verificacion/PageHomeActivacion";
import PageHomePortabilidad from "./pages/verificacion/PageHomePortabilidad";
import PageHomeRecuperacion from "./pages/verificacion/PageHomeRecuperacion";
import PageErrorActivacionOcr from "./pages/activacion/PageErrorActivacionOcr";
import PageCarga from "./pages/activacion/PageCarga";
import PageCargaPortabilidad from "./pages/portabilidad/PageCargaPortabilidad";
import PageErrorRecuperacionOcr from "./pages/recuperacion/PageErrorRecuperacionOcr";
import PageErrorPortabilidadOcr from "./pages/portabilidad/PageErrorPortabilidadOcr";
import PageCargaRecuperacion from "./pages/recuperacion/PageCargaRecuperacion";
// import Documento from "./components/Documento";
import PageRegistroActividad from "./pages/registroActividad/PageRegistroActividad";
import PageDetalleActividadActivaciones from "./pages/registroActividad/PageDetalleActividadActivaciones";
import PageDetalleActividadActivacionesMensual from "./pages/registroActividad/PageDetalleActividadActivacionesMensual";
import PageDetalleActividadPortaEfectiva from "./pages/registroActividad/PageDetalleActividadPortaEfectiva";
import PageDetalleActividadPortaEfectivaMensual from "./pages/registroActividad/PageDetalleActividadPortaEfectivaMensual";
import PageDetalleActividadPortaNoEfectiva from "./pages/registroActividad/PageDetalleActividadPortaNoEfectiva";
import PageDetalleActividadPortaNoEfectivaMensual from "./pages/registroActividad/PageDetalleActividadPortaNoEfectivaMensual";
import PageDetalleActividadMantenimiento from "./pages/registroActividad/PageDetalleActividadMantenimiento";
import PageDetalleActividadMantenimientoMensual from "./pages/registroActividad/PageDetalleActividadMantenimientoMensual";
import PageSolicitudReingresoPorta from "./pages/registroActividad/PageSolicitudReingresoPorta";

import PageConsultaHome from "./pages/consultaHome/PageConsultaHome";
import PageConsultaHomeClausulaFirma from "./pages/consultaHome/PageConsultaHomeClausulaFirma";
import PageConsultaHomeOferta from "./pages/consultaHome/PageConsultaHomeOferta"; 
import PageConsultaHomeResumen from "./pages/consultaHome/PageConsultaHomeResumen";

function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return (
      <div>
        <NavbarLogin setToken={setToken} />
        <PageLogin setToken={setToken} />
      </div>
    );
  }

  return (
    <div>
      <Navbar setToken={setToken} />
      <Routes>
        {/* pantalla de inicio */}
        <Route path="/" element={<PageMenu />} />

        {/* pantalla Mantenimiento */}
        <Route path="/dashboard" element={<PageHomeDashboard />} />
        <Route path="/usuario" element={<PageHomeUsuario />} />
        <Route path="/comercializador" element={<PageHomeComercializador />} />
        <Route path="/canal" element={<PageHomeCanal />} />

        {/* pantallas para portabilidad */}
        <Route
          path="/portabilidad/datos-cliente"
          element={<PageCargaPortabilidad />}
        />
        <Route
          path="/portabilidad/datos-portabilidad"
          element={<PagePortabilidad />}
        /> 
        <Route
          path="/portabilidad/validar-datos"
          element={<PageValidacionPortabiidad />}
        />
        <Route
          path="/portabilidad/finalizada"
          element={<PageFinalizacionPortabillidad />}
        />

        {/* pantallas para activacion */}
        <Route path="/activacion/validar-datos" element={<PageCarga />} />

        <Route
          path="/activacion/datos"
          element={<PageValidacionActivacion />}
        />

        <Route
          path="/activacion/finalizada"
          element={<PageFinalizacionActivacion />}
        />
        <Route
          path="/activacion/error-activacion"
          element={<PageErrorActivacion />}
        />
        <Route path="/activar/error-ocr" element={<PageErrorActivacionOcr />} />
        <Route path="/recuperacion/error-ocr" element={<PageErrorRecuperacionOcr />} />
        <Route path="/portabilidad/error-ocr" element={<PageErrorPortabilidadOcr />} />

        {/* pantallas para recuperacion */}
        <Route
          path="/recuperacion/datos-cliente"
          element={<PageCargaRecuperacion />}
        />
        <Route
          path="/recuperacion/datos-recuperacion"
          element={<PageRecuperacion />}
        />
        
        <Route
          path="/recuperacion/finalizada"
          element={<PageFinalizacionRecuperacion />}
        />

        <Route
          path="/recuperacion/error-recuperacion"
          element={<PageErrorRecuperacion />}
        />

        {/* firma */}
        <Route path="/portabilidad/firma" element={<PageFirma />} />

        {/* pantallas de verificacion */}

        <Route
          path="/verificacion/error-verificacion"
          element={<PageErrorVerificacion />}
        />
        <Route path="/activacion/cda" element={<PageHomeActivacion />} />
        <Route path="/portabilidad/cda" element={<PageHomePortabilidad />} />
        <Route path="/recuperacion/cda" element={<PageHomeRecuperacion />} />

        {/* Documento */}
        {/* <Route path="/portabilidad/documento" element={<Documento />} /> */}

        {/* Registro de Actividad 1 Semana*/}
        <Route path="/registeractivity" element={<PageRegistroActividad />} />
        <Route path="/detailactivity/activations" element={<PageDetalleActividadActivaciones />} />
        <Route path="/detailactivity/portability/effective" element={<PageDetalleActividadPortaEfectiva />} />
        <Route path="/detailactivity/portability/refused" element={<PageDetalleActividadPortaNoEfectiva />} />
        <Route path="/detailactivity/maintenance" element={<PageDetalleActividadMantenimiento />} />

        {/* Registro de Actividad Mensual*/}
        <Route path="/detailactivity/activations/currentmonth" element={<PageDetalleActividadActivacionesMensual />} />
        <Route path="/detailactivity/maintenance/currentmonth" element={<PageDetalleActividadMantenimientoMensual />} />
        <Route path="/detailactivity/portability/effective/currentmonth" element={<PageDetalleActividadPortaEfectivaMensual />} />
        <Route path="/detailactivity/portability/refused/currentmonth" element={<PageDetalleActividadPortaNoEfectivaMensual />} />
        <Route path="/detailactivity/portability/re-entry" element={<PageSolicitudReingresoPorta />} />
      
      {/* Pantallas Consulta Home */}
      <Route path="/consultahome" element={<PageConsultaHome />} />
      <Route path="/consultahome/clausulas" element={<PageConsultaHomeClausulaFirma />} />
      <Route path="/consultahome/oferta" element={<PageConsultaHomeOferta />} />
      <Route path="/consultahome/resumen" element={<PageConsultaHomeResumen />} />

      
      </Routes>
    </div>
  );
}

export default App;
