import banermin from '../img/banner-min.png'
import banner from '../img/Banner.png'
import bienvenida from '../img/Bienvenido.png'

const Banner = () => {
  return (
    <div>
      <div className='bienvenida-banner'>
        <img src={bienvenida} alt='' className='img-bienvenida' />
      </div>
      <div className='contenedor-banner banner-movil'>
        <img src={banermin} alt='bannermin' className='bannermin' />
      </div>
      <div className='banner-desk'>
        <img src={banner} alt='banner' className='banner' />
      </div>
      <div className='contenedor-informacion-opciones d-flex justify-content-center'>
        <div className='cuadro-informacion-opciones'>
          <p className=''>Selecciona la gestión Prepago que deseas realizar</p>
        </div>
      </div>
    </div>
  )
}

export default Banner
