import Header from '../../components/Header'
import FormDetalleActividadMantenimientoMensual from '../../components/registroActividad/FormDetalleActividadMantenimientoMensual'

const PageDetalleActividadMantenimientoMensual = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Mantenimientos en la Web este Mes'}
      />
      <FormDetalleActividadMantenimientoMensual />
    </div>
  )
}

export default PageDetalleActividadMantenimientoMensual
