import React from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import imgUsuarios from "../../img/mantUsuarios.png";
import imgCanal from "../../img/mantCanal.png";
import imgCm from "../../img/mantCm.png";

function PageHomeDashboard() {
  let showUser = false;
  if(JSON.parse(sessionStorage.getItem('dataUser')).roleUser !== "VENDEDOR"){
    showUser = true;
  }

  if (showUser) {
    return (
      <div>
        <Header subT={"Mantenimientos de Plataforma"} />
        <div className="container menu">
          {/* inicio card mantenimiento usuario */}
          <div>
            <div>
              <div className="contenedor-img-card">
                <img src={imgUsuarios} alt="activar" className="img-card" />
              </div>
              <Link className="tarjeta" to={"/usuario"}>
                <div className="text-card">Mantenimiento Usuarios</div>
                <div className="footer-card">
                  <span className="fw-bold span-card">Iniciar</span>
                </div>
              </Link>
            </div>
          </div>
          {/* inicio card mantenimiento usuario */}

          {/* inicio card mantenimiento Comercializadores */}
          <div>
            <div>
              <div className="contenedor-img-card">
                <img src={imgCm} alt="activar" className="img-card" />
              </div>
              <Link className="tarjeta" to={"/comercializador"}>
                <div className="text-card">Mantenimiento Comercializador</div>
                <div className="footer-card">
                  <span className="fw-bold span-card">Iniciar</span>
                </div>
              </Link>
            </div>
          </div>
          {/* inicio card mantenimiento Comercializadores */}

          {/* inicio card mantenimiento Canales */}
          <div>
            <div>
              <div className="contenedor-img-card">
                <img src={imgCanal} alt="activar" className="img-card" />
              </div>
              <Link className="tarjeta" to={"/canal"}>
                <div className="text-card">Mantenimiento <br/>Canal</div>
                <div className="footer-card">
                  <span className="fw-bold span-card">Iniciar</span>
                </div>
              </Link>
            </div>
          </div>
          {/* inicio card mantenimiento Canales */}
        </div>
      </div>
    );
  }else{
    return (
      <div>
        <Header subT={"Mi Perfil"} />
      </div>
    );
  }

}

export default PageHomeDashboard;
