import React from "react";
import Header from "../../components/Header";
import FormHomeRecuperacion from "../../components/validacion/FormHomeRecuperacion";

function PageHomeRecuperacion() {
  return (
    <div>
      <Header subT={"Datos de la gestión"} />
      <div className="contenedor-informacion">
        <div className="contenedor-info-extra-menor">
          <p>Completa los datos para iniciar la gestión</p>
        </div>
      </div>
      <FormHomeRecuperacion />
    </div>
  );
}

export default PageHomeRecuperacion;
