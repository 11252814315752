import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function statusOcr(id) {
    return new Promise((resolve, reject) => {
    axios
        .get(`${baseUrl.url}statusOcr/${id}`)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}