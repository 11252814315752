import "../style.css";

function OfertaCard({ oferta}) {
    const handleSelect = (oferta) => {
        sessionStorage.setItem("selectedOffer", JSON.stringify(oferta)); 
        window.location.href = "/consultahome/resumen";
    }

    return (
        <div className="oferta-container">
            <div className="oferta-container__title">
                <h5>{oferta.oferta}</h5>
            </div>
            <div className="oferta-container__opciones">
                <div className="oferta-container__opciones-monto">
                    <p>Monto Maximo $ {oferta.limitecredito} </p>
                </div>
                <div className="oferta-container__opciones-boton">
                    <button onClick={() => handleSelect(oferta)} className="btn btn-sm btn-primary">Elegir</button>
                </div>
            </div>
            <div className="oferta-container__descripcion">
                <p>
                    {oferta.mensaje}
                </p>
            </div>
        </div>
    );
}

export default OfertaCard; 