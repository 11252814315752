import { useState } from 'react'
import Header from '../../components/Header'
import FormRecuperacion from '../../components/recuperaion/FormRecuperacion'
import { obtenerIdGestion } from '../../services/DbServices'

const PageRecuperacion = () => {
  const [idGestion, setIdGestion] = useState()

  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  return (
    <div>
      <Header
        title={'Mantenimiento de Número'}
        subT={
          'Completa los datos para iniciar el proceso de mantenimiento de número.'
        }
        idGestion={`Id gestión: ${idGestion}`}
      />
      <FormRecuperacion />
    </div>
  )
}

export default PageRecuperacion
