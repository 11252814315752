import { Link } from "react-router-dom";
import imgActivar from "../img/activar.png";
import imgPortar from "../img/portar.png";
import imgMantenimiento from "../img/recuperar.png";
// import imgMantPlataforma from "../img/mantenimiento.png";
import imgConsultaHome from "../img/consultaHome.png";

const Opciones = () => {
  sessionStorage.setItem("nip", "");
  sessionStorage.removeItem("correo");
  sessionStorage.removeItem("pdf");
  sessionStorage.removeItem("cda");
  sessionStorage.removeItem("dui");
  sessionStorage.removeItem("telefono");
  sessionStorage.removeItem("uid");
  sessionStorage.removeItem("cip");
  sessionStorage.removeItem("temporalMsisdn");
  sessionStorage.removeItem("nombre");
  sessionStorage.removeItem("compania");
  sessionStorage.removeItem("departamento");
  sessionStorage.removeItem("trx");
  sessionStorage.removeItem("telContacto");
  sessionStorage.removeItem("municipio");
  sessionStorage.removeItem("apellido");
  sessionStorage.removeItem("direccion");
  sessionStorage.removeItem("fechaNac");
  sessionStorage.removeItem("data_perfilamiento");
  sessionStorage.removeItem("montos");
  sessionStorage.removeItem("tempPDF");
  sessionStorage.removeItem("selectedOffer");

  /*  if (JSON.parse(sessionStorage.getItem('dataUser')).roleId !== 1) {
     showUser = true;
   } */

  const roleId = JSON.parse(sessionStorage.getItem('dataUser'))?.roleId;
  const showUser = roleId === 1 || roleId === 2;

  return (
    <div className="container menu">
      {/* inicio card mantenimiento usuario */}

      {/* <div>
          <div>
            <div className="contenedor-img-card">
              <img src={imgMantPlataforma} alt="activar" className="img-card" />
            </div>
            <Link className="tarjeta" to={"/dashboard"}>
              <div className="text-card">Mantenimiento Plataforma</div>
              <div className="footer-card">
                <span className="fw-bold span-card">Iniciar</span>
              </div>
            </Link>
          </div>
        </div> */}


      {/* inicio card mantenimiento usuario */}

      {/* inicio card activación */}
      {
        roleId !== 5 && (
          <div>
            <div>
              <div className="contenedor-img-card">
                <img src={imgActivar} alt="activar" className="img-card" />
              </div>
              <Link className="tarjeta" to={"/activacion/cda"}>
                <div className="text-card">Activación</div>
                <div className="footer-card">
                  <span className="fw-bold span-card">Iniciar</span>
                </div>
              </Link>
            </div>
          </div>
        )
      }
      {/* fin card activación */}

      {/* inicio card portar */}
      {
        roleId !== 5 && (
          <div>
            <div className="contenedor-img-card">
              <img src={imgPortar} alt="activar" className="img-card" />
            </div>
            <Link className="tarjeta" to={"/portabilidad/cda"}>
              <div className="text-card">Portabilidad</div>
              <div className="footer-card">
                <span className="fw-bold span-card">Iniciar</span>
              </div>
            </Link>
          </div>
        )
      }
      {/* fin card portar */}

      {/* inicio card Mantenimiento */}
      {
        roleId !== 5 && (
          <div>
            <div className="contenedor-img-card">
              <img src={imgMantenimiento} alt="activar" className="img-card" />
            </div>
            <Link className="tarjeta" to={"/recuperacion/cda"}>
              <div className="text-card">
                Mantenimiento <br /> de número
              </div>
              <div className="footer-card">
                <span className="fw-bold span-card">Iniciar</span>
              </div>
            </Link>
          </div>
        )
      }

      {/* fin card Mantenimiento */}

      {/* inicio card Consulta Home */}
      {(showUser || roleId === 4 || roleId === 5) && (
        <div>
          <div>
            <div className="contenedor-img-card">
              <img src={imgConsultaHome} alt="activar" className="img-card" />
            </div>
            <Link className="tarjeta" to={"/consultahome"}>
              <div className="text-card">Consulta Internet Hogar</div>
              <div className="footer-card">
                <span className="fw-bold span-card">Iniciar</span>
              </div>
            </Link>
          </div>
        </div>
      )
      }

      {/* fin card Consulta Home */}


    </div>
  );
};

export default Opciones;
