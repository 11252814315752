import { useEffect, useState } from "react";
import { FloatLabel } from "../../js/inputs";
import axios from 'axios';
import { baseUrl } from '../baseURL';
import imgLoading from "../../img/Loading.gif";
import db from "../../helpers/indexedDBHelper";

// inicio el script para las animaciones de los input
function FormDetalleActividadPortaEfectivaMensual() {

    const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
    const [codVendedor] = useState(sessionDate.codigoUser);
    const [portaEfectivaCurrentMonth, setPortaEfectivaCurrentMonth] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    FloatLabel.init();
    DetallePortaEfectivaCurrentMonth();
  },[]);

  // Solicitud AXIOS de Activaciones 1 Semana
  const DetallePortaEfectivaCurrentMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth/porta`, {
        params: {
          codVendedor,
        }
      })
      .then((response) => {
        if(response.data.status === 200){

          generarDataUpdate(response.data.data)
          .then((dataUpdateFiltered) => {
            const efectiva = dataUpdateFiltered.filter(item => item.estado === 'FINALIZADO_AP');
            setPortaEfectivaCurrentMonth(efectiva);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error al generar dataUpdate:', error);
          });

        }else{
            console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const generarDataUpdate = async (data) => {
    try {
     const tokens = await db.tokens.toArray(); 
     const dataUpdateArray = await Promise.all(
       data.map(async (item) => {
         const telefono = item.telefono; 
         const tokenExists = tokens.find(token => token.telefono === telefono); 
 
         if (tokenExists) {
           try {
             const estado = await getCheckResponse(tokenExists.token);
             return { ...item, estado };
           } catch (error) {
             console.error('Error al obtener el estado de portabilidad:', error);
             return null;
           }
         } else {
           return { ...item, estado: 'N/A' };
         }
       })
     ); 
 
     const filteredData = dataUpdateArray.filter(Boolean);
     return filteredData;
    } catch (error) { 
       console.error('Error al obtener los tokens:', error);
       return null;
    }
   };
 
   // Validacion Estado Porta TELCASV
   const getCheckResponse = async (token) => {
     try {
       const response = await
         axios({
           method: 'post',
           url: `${baseUrl.url}get-check-response`,
           params: {
             "tipo": "CALL_API_MOVISTAR_DATABASE",
             "token": token,
           }
         });
 
       return response.data.estado;
     } catch (error) {
       console.log(error);
       throw error;
     }
   };

  return (
    <div className="container h-100">
        <div className="row col-md-3 col-sm-12 mb-3"><a className="btn btn_verde btn-sm" href='/registeractivity'>Resumen</a></div>
        <div className="row">
        {
          isLoading ? (

            <div className='contenedor-informacion contenedor-gif'>
              <img
                src={imgLoading}
                alt='consultando-estado'
                id='loading'
                style={{ width: '100px' }}
              />
            </div>
          ) : ( 
            
            portaEfectivaCurrentMonth.map((data, i) => (
                <div key={i} className="card mb-4">
                    <div className="card-header">
                        {new Date(data.createdAt).toLocaleString('es-ES')}
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">{data.numeroportar}</li>
                        <li className="list-group-item">DUI: {data.num_documento}</li>
                        <li className="list-group-item">CDA: {data.cda}</li>
                        <li className="list-group-item">Temporal: {data.telefono}</li>
                        <li className="list-group-item">Estado: Finalizado</li>
                    </ul>
                </div>
            ))
          )
        }
        </div>
    </div>
  );
}

export default FormDetalleActividadPortaEfectivaMensual;
