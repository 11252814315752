import Header from '../../components/Header'
import FormDetalleActividadMantenimiento from '../../components/registroActividad/FormDetalleActividadMantenimiento'

const PageDetalleActividadMantenimiento = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Mantenimientos en la Web'}
      />
      <FormDetalleActividadMantenimiento />
    </div>
  )
}

export default PageDetalleActividadMantenimiento
