import { Formik, Field } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import loading from "../../img/Loading.gif";
import { verificarCompania } from "../../services/VerificarCompaniaServices";

import { FloatLabel } from "../../js/inputs";
import { CreateDataDonor, GestionReciente } from "../../services/DbServices";

// Valido todos los campos del formulario
const validacionDeFormulario = Yup.object().shape({
  numero: Yup.string()
    .matches(/^[0-9]+$/, "Solo puede contener números")
    .min(8, "El número debe tener 8 dígitos")
    .required("Número a portar es requerido"),
  tipo: Yup.string().required("Seleccione tipo de portación"),
  compania: Yup.string().required("Seleccione compañía actual"),
  cda: Yup.string()
    .min(8, "Número de CDA debe contener 8 digitos")
    .max(8, "Número de CDA debe contener 8 digitos")
    .matches(/^\d{8}/, "Ingrese número de CDA")
    .required("Número de CDA inválido"),

  correo: Yup.string()
    .email("Correo electrónico no válido")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      "Correo electrónico no válido"
    )
    .required("Debes ingresar un correo válido"),
});

function errorHidden() {
  document.getElementById("compania-invalida").hidden = true;
  document.getElementById("numero-existente").hidden = true;
  document.getElementById("solicitud-existente").hidden = true;
}

// inicio el script para las animaciiones de los input
function FormPortabilidad() {
  useEffect(() => {
    FloatLabel.init();
  });

  const meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const navigate = useNavigate();

  return (
    <div className="container h-100">
      <div className="col-md-12">
        {/* props : css, type, mLength */}
        <Formik
          // inicializando los valores de los input
          initialValues={{
            numero: "",
            tipo: "",
            compania: "",
            cda: sessionStorage.getItem("cda"),
            correo: "",
          }}
          validationSchema={validacionDeFormulario}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            document.getElementById("btn-continuar").hidden = true;
            document.getElementById("loading").hidden = false;

            verificarCompania(values.numero).then((response) => {
              if (response["operadorActual"] === "telefonica") {
                document.getElementById("btn-continuar").hidden = false;
                document.getElementById("loading").hidden = true;
                document.getElementById("numero-existente").hidden = false;
              } else if (response["operadorActual"] !== values.compania) {
                document.getElementById("btn-continuar").hidden = false;
                document.getElementById("loading").hidden = true;
                document.getElementById("compania-invalida").hidden = false;
              } else if (response["operadorActual"] === values.compania) {
                sessionStorage.setItem("compania", values.compania);
                sessionStorage.setItem("telefono", values.numero);
                sessionStorage.setItem("correo", values.correo);

                GestionReciente(values.numero).then((response) => {
                  const solicitud = response["respuesta"];

                  if (solicitud === null) {
                    const data = {
                      numero_portar: values.numero,
                      donor: values.compania,
                      correo: values.correo,
                      trx: sessionStorage.getItem("trx"),
                      codVendedor: sessionStorage.getItem("codVendedor"),
                      paso: 3,
                    };
                    const datosCodificado = encodeURIComponent(
                      JSON.stringify(data)
                    );
                    CreateDataDonor(datosCodificado).then((response) => {
                      navigate("/portabilidad/validar-datos");
                    });
                  } else {
                    // Crear una instancia de Date con la cadena de fecha
                    const fecha = new Date(response["respuesta"]["createdAt"]);

                    // Obtener los componentes de la fecha y hora
                    const d = fecha.getDate();
                    const m = meses[fecha.getMonth()]; // Los meses en JavaScript van de 0 a 11
                    const h = fecha.getHours();
                    const min = fecha.getMinutes();
                    const seg = fecha.getSeconds();

                    // Formatear la fecha y hora
                    const fechaFormateada = `${d} de ${m}`;
                    const horaFormateada = `${h}:${min}:${seg}`;

                    document.getElementById("btn-continuar").hidden = true;
                    document.getElementById("loading").hidden = true;
                    document.getElementById("btn-Aceptar").hidden = false;
                    const error = document.getElementById(
                      "solicitud-existente"
                    );
                    error.hidden = false;
                    error.textContent =
                      "Ya posee una gestión ingresada en menos de 24 horas - " + fechaFormateada + " " + horaFormateada
                  }
                });
              }
            });
          }}
        >
          {({ handleChange, handleSubmit, errors }) => (
            <Form
              className="d-flex justify-content-center align-items-center flex-column"
              onSubmit={handleSubmit}
            >
              {/* Numero a portar */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"numero"}>Número a portar</label>
                  <Field
                    type={"text"}
                    id={"numero"}
                    name={"numero"}
                    data-placeholder=""
                    maxLength={8}
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {errors.numero ? (
                    <div className="message-error">{errors.numero}</div>
                  ) : null}
                  <div
                    className="message-error"
                    id="solicitud-existente"
                    hidden
                  ></div>
                </div>
              </Col>

              {/* Tipo de portación */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container active">
                  <label htmlFor="  ">Tipo de portación</label>
                  <Field
                    component="select"
                    id="tipo"
                    name="tipo"
                    onChange={handleChange}
                  >
                    <option value="">Seleccionar tipo de portación</option>
                    <option value="1">Prepago a prepago</option>
                  </Field>
                  {errors.tipo ? (
                    <div className="message-error">{errors.tipo}</div>
                  ) : null}
                </div>
              </Col>

              {/* Compañia actual */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container active">
                  <label htmlFor="  ">Operador</label>
                  <Field
                    component="select"
                    id="compania"
                    name="compania"
                    onChange={handleChange}
                  >
                    <option value="">Selecciona tu compañía actual</option>
                    <option value="tigo">Tigo</option>
                    <option value="claro">Claro</option>
                    <option value="digicel">Dígicel</option>
                  </Field>
                  {errors.compania ? (
                    <div className="message-error">{errors.compania}</div>
                  ) : null}
                  <div className="message-error" id="compania-invalida" hidden>
                    El número no pertenece a la compañía seleccionada
                  </div>
                  <div className="message-error" id="numero-existente" hidden>
                    Número ya es Movistar
                  </div>
                </div>
              </Col>

              {/* Numero CDA */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"cda"}>Número CDA</label>
                  <Field
                    type={"text"}
                    id={"cda"}
                    name={"cda"}
                    data-placeholder=""
                    maxLength={8}
                    autoComplete="off"
                    readOnly
                    style={{ backgroundColor: "#e6b8f126" }}
                  />
                  {errors.cda ? (
                    <div className="message-error">{errors.cda}</div>
                  ) : null}
                </div>
              </Col>

              {/* Correo */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"correo"}>Correo</label>
                  <Field
                    type={"text"}
                    id={"correo"}
                    name={"correo"}
                    data-placeholder=""
                    autoComplete="off"
                  />
                  {errors.correo ? (
                    <div className="message-error">{errors.correo}</div>
                  ) : null}
                </div>
              </Col>
              <br />
              <button
                type="submit"
                className="btn_verde mb-4"
                id="btn-continuar"
                onClick={errorHidden}
              >
                Continuar
              </button>
              <Link className="btn_verde" id="btn-Aceptar" to={"/"} hidden>
                Aceptar
              </Link>
              <img src={loading} alt="validando-cda" id="loading" hidden />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FormPortabilidad;
