import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function crearNip(telefono) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl.url}crear-solicitud-nip/${telefono}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function validarNip(datos) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrl.url}validar-estado-nip`, datos)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                console.log(error)
                reject(error);
            });
    });
}

