import { Row, Col, Card, Button, Container } from "react-bootstrap";
import { useEffect } from "react";
import { FloatLabel } from "../../js/inputs";
import OfertaCard from "./helpers/OfertaCard";

function FormConsultaHomeOferta() {
    const ofertas = JSON.parse(sessionStorage.getItem("montos"));
    useEffect(() => {
        FloatLabel.init();
    });

    return (
        <>
            <Col xs={12} sm={12} md={9} lg={5} xl={6} className="d-flex align-items-center m-auto">
                <h3 className="text-center m-auto">
                    Montos Pre Aprobados
                </h3>
            </Col>

            <div className="container mt-2" /* style={{border: '2px solid black'}} */>
                {ofertas.map((oferta, i) => (
                    <OfertaCard key={i} oferta={oferta} />
                ))}
            </div>
        </>
    );
}

export default FormConsultaHomeOferta; 