import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";

// Crea un nuevo comercializador
async function setCreateMarketer(dataParams) {

    const resp = await axios.post(`${baseUrl.url}cm`, dataParams);

    return resp.data
}

const reloadWindows = () => {
    setInterval("location.reload()", 3000);
}

const FormComercializador = () => {
    const [show] = useState(true);
    const [nombre, setNombre] = useState();
    const [detalle, setDetalle] = useState();
    const handleClose = () => window.location.reload();
    const handleSend = async e  => {
        e.preventDefault();
        
        const validate = await setCreateMarketer({
            nombre, 
            detalle
        }); 

        if(validate.status === 201){
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">'+validate.message+'</div>';
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            reloadWindows();
          } else {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">'+validate.message+'</div>';
          }
    }

    useEffect(() => {
        FloatLabel.init();
      },[])

    
    return (
        <>

      <Modal show={show} className="custom-modal-width mt-4">
        <div style={{ maxWidth: "556px" }}>
            <form onSubmit={handleSend}>
            <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ChangePasswordLabel">Agregar Comercializador</h1>
                    </div>
                    
                    <div className="modal-body">
                        <div className="float-container float-container-form">
                            <label htmlFor={"form-nombre"}>NOMBRE</label>
                            <input type="text" id="form-nombre" name="form-nombre" data-placeholder="" autoComplete="off" required onChange={e => setNombre(e.target.value)} className="border-input form-control form-control-lg"/>
                        </div>
                        <div className="float-container float-container-form">
                            <label htmlFor={"form-detalle"}>DETALLE</label>
                            <input type="text" id="form-detalle" name="form-detalle" data-placeholder="" autoComplete="off" onChange={e => setDetalle(e.target.value)} className="border-input form-control form-control-lg"/>
                        </div>
                    </div>
                    <div id='alertDivChannel'></div>
                </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
                <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3"  type="button" onClick={handleClose}>Salir</button>
                <button id="btnSave" className="btn_verde btn-lg btn-block border-boton mb-3" type="submit">Guardar</button>
            </Modal.Footer>
            </form>
        </div>
      </Modal>
    </>

    );

}

export default FormComercializador; 