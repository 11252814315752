import Header from '../../components/Header';
import { Navigate } from 'react-router-dom';
import FormConsultaHomeResumen from '../../components/consultaHome/FormConsultaHomeResumen';

const PageConsultaHomeResumen = () => {
  const data = sessionStorage.getItem("data_perfilamiento");

  if (!data) {
    return <Navigate to="/consultahome" />;
  }

  return (
    <div>
      <Header
        title={'Internet Hogar'}
      />
      <FormConsultaHomeResumen />
    </div>

  )
}

export default PageConsultaHomeResumen; 