import React, { useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Form, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FloatLabel } from "../../js/inputs";

// Valido todos los campos del formulario
const validacionDeFormulario = Yup.object().shape({
  numero: Yup.string()
    .matches(/^[0-9]+$/, "Solo puede contener números")
    .min(8, "El número debe tener 8 dígitos")
    .required("Número a recuperar es requerido"),

  correo: Yup.string()
    .email("Correo electrónico no válido")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      "Correo electrónico no válido"
    )
    .nullable(),

  cda: Yup.string().required("Número CDA inválido"),
});

// inicio el script para las animaciones de los input
function FormRecuperacion() {

  useEffect(() => {
    FloatLabel.init();
  });

  const navigate = useNavigate();

  return (
    <div className="container h-100">
      <div className="col-md-12">
        {/* props : css, type, mLength */}
        <Formik
          // inicializando los valores de los input
          initialValues={{
            numero: "",
            correo: "",
            cda: sessionStorage.getItem('cda'),
          }}
          validationSchema={validacionDeFormulario}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            sessionStorage.setItem("correo",values.correo)
            sessionStorage.setItem("telefono", values.numero)
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              })
            }, 0)
            navigate("/recuperacion/finalizada",{state: values});
          }}
        >
          {({ handleChange, handleSubmit, errors }) => (
            <Form
              className="d-flex justify-content-center align-items-center flex-column"
              onSubmit={handleSubmit}
            >
              {/* Numero a recuperar */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"numero"}>Número a recuperar</label>
                  <Field
                    type={"text"}
                    id={"numero"}
                    name={"numero"}
                    data-placeholder=""
                    maxLength={10}
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  {errors.numero ? (
                    <div className="message-error">{errors.numero}</div>
                  ) : null}
                </div>
              </Col>

              {/* Numero CDA */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"cda"}>Número CDA</label>
                  <Field
                    type={"text"}
                    id={"cda"}
                    name={"cda"}
                    data-placeholder=""
                    maxLength={10}
                    autoComplete="off"
                    readOnly
                    style={{ backgroundColor: "#e6b8f126" }}
                  />
                  {errors.cda ? (
                    <div className="message-error">{errors.cda}</div>
                  ) : null}
                </div>
              </Col>

              {/* Correo */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"correo"}>Correo</label>
                  <Field
                    type={"text"}
                    id={"correo"}
                    name={"correo"}
                    data-placeholder=""
                    autoComplete="off"
                  />
                  {errors.correo ? (
                    <div className="message-error">{errors.correo}</div>
                  ) : null}
                </div>
              </Col>
              <br />
              <button type="submit" className="btn_verde mb-4">
                Recuperar
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FormRecuperacion;
