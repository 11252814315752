import axios from "axios";
import { Formik, Field } from "formik";
import InputMask from 'react-input-mask';
import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import Select from 'react-select';
import loading from "../../img/Loading.gif";
import { FloatLabel } from "../../js/inputs";
import { baseUrl } from "../../components/baseURL";
import { v4 as uuidv4 } from "uuid";
import "./style.css"
import { validacionDeFormulario } from "./helpers/validationSchema";


const createPerfilamiento = async (data) => {
    try {
        const resp = await axios.post(`${baseUrl.url}create-perfil`, data);

        return resp.data;
    } catch (error) {
        console.log(error);
    }
}


/* TRUE si esta cargando, FALSE si no esta cargando */
const isLoading = (status) => {
    document.getElementById("loading").hidden = !status;
    document.getElementById("btn-continuar").hidden = status;
}

function FormConsultaHome() {
    const cod_vendedor = sessionStorage.getItem("codVendedor");
    const [dpList, setDpList] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [selected, setSelected] = useState({ departamento: "", municipio: "" });
    const initialValues = {
        nombres: "",
        tipo_trx: 4,
        id_trx: uuidv4(),
        apellidos: "",
        tipo_doc: 1,
        num_doc: "",
        correo: "",
        contacto: "",
        departamento: "",
        nombre_dep: "",
        municipio: "",
        nombre_mun: "",
        direccion: "",
        cod_vendedor,
    };

    useEffect(() => {
        FloatLabel.init();
    });

    /* Obtiene el listado de  departamentos al inicializar el componente */
    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = async () => {
        try {
            const response = await axios.get(`${baseUrl.url}dp`);

            if (response.data.status === 200) {
                const formattedData = [
                    ...response.data.data.map((dp) => ({
                        value: dp.id,
                        label: dp.nombre,
                    })),
                ];
                setDpList(formattedData);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMunicipalities = async (id) => {
        try {
            const response = await axios.get(`${baseUrl.url}dp/m?id=${id}`);

            if (response.data.status === 200) {
                const formattedData = [
                    ...response.data.data.map((dp) => ({
                        value: dp.id,
                        label: dp.nombre,
                    })),
                ];
                setMunicipalities(formattedData);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /*  Valida que se haya seleccionado un dp de la lista, y obtiene los municipios
        pertenecientes al dp seleccionado
    */
    const handleSelectDpChange = (fieldName, form) => (selectedOption) => {
        if (selectedOption.value !== 0) {
            form.handleChange(fieldName)(selectedOption.value.toString());
            form.handleChange("nombre_dep")(selectedOption.label);
            setSelected((prevData) => ({
                ...prevData,
                [fieldName]: selectedOption ? selectedOption.value : "",
            }));
            getMunicipalities(selectedOption.value);
        } else {
            setMunicipalities([]);
        }
    };

    const handleSelectMChange = (fieldName, form) => (selectedOption) => {
        form.handleChange(fieldName)(selectedOption.value.toString());
        form.handleChange("nombre_mun")(selectedOption.label);
    }
    
    // Estilos para el react-select 
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '15px',
            fontFamily: 'telefonica-light',
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'telefonica-light',
            backgroundColor: state.isSelected ? 'var(--color-white-movistar)' : 'transparent',
            color: state.isSelected ? 'var(--color-black)' : 'var(--color-gray)',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 2,
        }),
    };


    return (
        <div className="container h-100">
            <div className="col-md-12">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validacionDeFormulario}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        isLoading(true);

                        const info = {
                            id_trx: values.id_trx,
                            nombres: values.nombres,
                            apellidos: values.apellidos,
                            tipo_doc: values.tipo_doc,
                            num_doc: values.num_doc,
                            correo: values.correo,
                            contacto: values.contacto,
                            departamento: values.departamento,
                            nombre_dep: values.nombre_dep,
                            municipio: values.municipio,
                            nombre_mun: values.nombre_mun,
                            direccion: values.direccion,
                            cod_vendedor
                        };

                        createPerfilamiento(values).then((response) => {
                            isLoading(false);
                            if (response.status === 201) {
                                document.getElementById("error").hidden = true;
                                sessionStorage.setItem("data_perfilamiento", JSON.stringify(info));
                                window.location.href = "/consultahome/clausulas";
                            } else {
                                document.getElementById("error").hidden = false;
                            }
                        }).catch((error) => {
                            isLoading(false);
                            document.getElementById("error").hidden = false;
                            console.log(error.message)
                        });
                    }}
                >

                    {({ handleChange, handleSubmit, errors }) => (
                        <Form
                            className="d-flex justify-content-center align-items-center flex-column"
                            onSubmit={handleSubmit}
                        >
                            <h3>Captura de informacion cliente</h3>
                            {/* Nombres */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="float-container float-container-form">
                                    <label htmlFor={"nombres"}>Nombre completo</label>
                                    <Field
                                        type={"text"}
                                        id={"nombres"}
                                        name={"nombres"}
                                        data-placeholder=""
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    {errors.nombres ? (
                                        <div className="message-error">{errors.nombres}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* num_doc */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="float-container float-container-form">
                                    <label htmlFor={"dui"}>DUI</label>
                                    <Field
                                        type={"text"}
                                        id={"num_doc"}
                                        name={"num_doc"}
                                        data-placeholder=""
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    {errors.num_doc ? (
                                        <div className="message-error">{errors.num_doc}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* Departamento */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="select-container active">
                                    <label htmlFor={"departamento"}>Departamento</label>
                                    <Field name="departamento">
                                        {({ field, form }) => (
                                            <Select
                                                id="departamento"
                                                options={dpList}
                                                isSearchable
                                                value={dpList.find((option) => option.value === selected.departamento)}
                                                onChange={handleSelectDpChange('departamento', form)}
                                                placeholder="Seleccione un departamento..."
                                                styles={customStyles}
                                                className=""
                                            />
                                        )}
                                    </Field>
                                    {errors.departamento ? (
                                        <div className="message-error">{errors.departamento}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* Municipio */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="select-container active">
                                    <label htmlFor="municipio">Municipio</label>
                                    <Field name="municipio">
                                        {({ field, form }) => (
                                            <Select
                                                options={municipalities}
                                                isSearchable
                                                value={municipalities.find((option) => option.value === selected.municipio)}
                                                onChange={handleSelectMChange("municipio", form)}
                                                placeholder="Seleccione un municipio..."
                                                styles={customStyles}
                                                name="municipio"
                                                className=""
                                            />
                                        )}
                                    </Field>

                                    {errors.municipio ? (
                                        <div className="message-error">{errors.municipio}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* Direccion */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="float-container float-container-form">
                                    <label htmlFor={"direccion"}>Direccion de instalación</label>
                                    <Field
                                        type={"text"}
                                        id={"direccion"}
                                        name={"direccion"}
                                        data-placeholder=""
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    {errors.direccion ? (
                                        <div className="message-error">{errors.direccion}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* Correo */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="float-container float-container-form">
                                    <label htmlFor={"correo"}>Correo electronico</label>
                                    <Field
                                        type={"text"}
                                        id={"correo"}
                                        name={"correo"}
                                        data-placeholder=""
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    {errors.correo ? (
                                        <div className="message-error">{errors.correo}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>

                            {/* numero */}
                            <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                                <div className="float-container float-container-form">
                                    <label htmlFor={"numero"}>Numero contacto</label>
                                    <Field
                                        type={"text"}
                                        id={"contacto"}
                                        name={"contacto"}
                                        data-placeholder=""
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    {errors.contacto ? (
                                        <div className="message-error">{errors.contacto}</div>
                                    ) : null}
                                    <div
                                        className="message-error"
                                        id="message-error"
                                        hidden
                                    ></div>
                                </div>
                            </Col>
                            <br />
                            <p className="error" id="error" hidden>Ocurrio un error, reintenta nuevamente</p>
                            <button
                                type="submit"
                                className="btn_verde mb-4"
                                id="btn-continuar"
                            >
                                Continuar
                            </button>
                            <img src={loading} alt="validando-informacion" id="loading" hidden />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default FormConsultaHome;