import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import Modal from "react-bootstrap/Modal";
import { FloatLabel } from "../../../js/inputs";

async function setUpdateRol(dataParams) {

    const resp = await axios.put(`${baseUrl.url}usuario/rol`, dataParams);

    return resp.data
}


function FormUsuarioRol({ data }) {
    const [show] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rol, setRol] = useState(0);
    const codVendedor = data.codVendedor; 

    useEffect(() => {
        getRoles(); 
        console.log(codVendedor); 
    }, []);

    const getRoles = async () => {
        await axios
            .get(`${baseUrl.url}usuario/roles`)
            .then((response) => {
                if (response.data.status === 200) {
                    setRoles(response.data.data);
                } else {
                    console.log(response.data.message);
                }
            })
            .catch((error) => console.log(error));
    };

    const reloadWindows = () => {
        setInterval("location.reload()", 3000);
    }

    const handleClose = () => {
        window.location.reload();
    };

    const handleSelectChange = (event) => {
        console.log(event.target.value); 
        setRol(event.target.value);
      };

      const handleSend = async () => {
        const validate = await setUpdateRol({
            rol, 
            codVendedor
        });

        if (validate.status === 200) {
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-success text-center" role="alert">' + validate.message + '</div>';
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnClose").style.display = "none";
            reloadWindows();
        } else {
            
            document.getElementById('alertDivChannel').innerHTML = '<div class="alert alert-danger text-center" role="alert">' + validate.message + '</div>';
        }
    }

    return (
        <Modal show={show} className="custom-modal-width mt-4">
            <div style={{ maxWidth: "556px" }}>
                <form onSubmit={handleSend}>
                    <Modal.Body style={{ overflowY: "auto", textAlign: "center" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Actualizar rol del usuario: {data.codVendedor}</h1>
                            </div>
                            <div className="modal-body">
                                <select name="rol" id="rol" className="form-control" onChange={handleSelectChange}>
                                    <option value="0">Seleccione un rol...</option>
                                    {roles.map((rol) => (
                                        <option value={rol.id}>{rol.nombre}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div id='alertDivChannel'></div>
                    </Modal.Body>
                    <Modal.Footer className="text-center">
                        <button id="btnClose" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleClose}>Cancelar</button>
                        <button id="btnSave" className="btn_verde btn-lg btn-block border-boton mb-3" type="button" onClick={handleSend} >Actualizar</button>
                    </Modal.Footer>
                </form>
            </div>
        </Modal>
    );
}

export default FormUsuarioRol; 