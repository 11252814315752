import { Formik, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IconCalendar } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid'
import { baseUrl } from '../baseURL'

import { FloatLabel } from '../../js/inputs'
import { obtenerOcr } from '../../services/ObtenerOcrServices'
import { CreateDataOcr, CreateTrxActCom } from '../../services/DbServices'

// Valido todos los campos del formulario
const validacionDeFormulario = Yup.object().shape({
  // dui: Yup.string()
  //   .min(9, 'Número de DUI inválido')
  //   .max(9, 'Número de DUI inválido')
  //   .matches(/^\d{9}/, 'Ingrese número de DUI sin guiones')
  //   .required('Número de DUI inválido'),

  nombres: Yup.string().required('Los nombres son requeridos'),
  apellidos: Yup.string().required('Los apellidos son requeridos'),

  correo: Yup.string()
    .email('Correo electrónico no válido')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Correo electrónico no válido'
    )
    .nullable(),

  // direccion: Yup.string().required('La dirección es requerida'),
  // cda: Yup.string()
  //   .min(8, 'Número de CDA debe contener 8 digitos')
  //   .max(8, 'Número de CDA debe contener 8 digitos')
  //   .matches(/^\d{8}/, 'Ingrese número de CDA')
  //   .required('Número de CDA inválido'),
})

// inicio el script para las animaciones de los input
function FormValidacionActivacion() {
  const navigate = useNavigate()
  const idTrx = uuidv4()
  var uid = sessionStorage.getItem('uid')

  const [dui, setDui] = useState('')
  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('')
  const [direccion, setDireccion] = useState('')
  const [fechaNac, setFechaNac] = useState('2000-01-01')
  const [municipio, setMunicipio] = useState('')
  const [departamento, setDepartamento] = useState('')
  const [listo, setListo] = useState(false)

  obtenerOcr(uid).then((response) => {
    if (response['visualZone']['documentNumber'] != null) {
      setDui(response['visualZone']['documentNumber']['value'])
    } else if (response['mrz']['documentNumber'] != null) {
      setDui(response['mrz']['documentNumber']['value'])
    } else {
      navigate('/activar/error-ocr')
    }

    if (response['visualZone']['name'] !== null) {
      setNombre(response['visualZone']['name']['value'])
    }
    if (response['visualZone']['surname'] !== null) {
      setApellido(response['visualZone']['surname']['value'])
    }
    if (response['visualZone']['addressStreet'] !== null) {
      setDireccion(response['visualZone']['addressStreet']['value'])
    }
    if (response['visualZone']['address'] !== null) {
      setMunicipio(response['visualZone']['address']['value'])
    }
    if (response['visualZone']['addressState'] !== null) {
      setDepartamento(response['visualZone']['addressState']['value'])
    }

    if (response['visualZone']['dob'] !== null) {
      setFechaNac(response['visualZone']['dob']['value'])
    } else if (response['mrz']['dob'] !== null) {
      setFechaNac(response['mrz']['dob']['value'])
    } else {
      setFechaNac('')
    }

    const data = {
      paso: 3,
      tipo_doc: 1,
      num_doc: dui,
      nombres: nombre,
      apellidos: apellido,
      direccion: direccion,
      fecha_nac: fechaNac,
      departamento: departamento,
      municipio: municipio,
      trx: sessionStorage.getItem('trx'),
    }
    const datosCodificado = encodeURIComponent(JSON.stringify(data))
    CreateDataOcr(datosCodificado).then((response) => {})
    setListo(true)
  })

  const [startDate, setStartDate] = useState(new Date(fechaNac))

  useEffect(() => {
    FloatLabel.init()
    try {
      const esValido = /^[\d-]+$/.test(fechaNac)
      if (esValido) {
        const f = new Date(fechaNac)
        f.setDate(f.getDate() + 1)
        setStartDate(f)
      } else {
        navigate('/activar/error-ocr')
      }
    } catch (error) {
      navigate('/activar/error-ocr')
    }
  }, [fechaNac, listo, navigate])

  function capturarDatos() {
    const data = {
      cda: sessionStorage.getItem('cda'),
      tipo: 1,
      trx: idTrx,
      codVendedor: sessionStorage.getItem('codVendedor'),
      paso: 1,
    }
    sessionStorage.setItem('trx', idTrx)
    const datosCodificado = encodeURIComponent(JSON.stringify(data))
    CreateTrxActCom(datosCodificado).then((response) => {
      window.location.replace(
        baseUrl['uri'] +
          '?idTrx=' +
          idTrx +
          '&tipo=1&idSolic=' +
          response['id'] +
          '&cda=' +
          response['cda'] +
          '&seller=' +
          response['codVendedor']
      )
    })
  }

  const datePickerRef = useRef(null)

  if (listo) {
    return (
      <div className='container h-100'>
        <div className='col-md-12'>
          {/* props : css, type, mLength */}
          <Formik
            // inicializando los valores de los input
            initialValues={{
              dui: dui,
              nombres: nombre,
              apellidos: apellido,
              direccion: direccion,
              fecha: fechaNac,
              cda: sessionStorage.getItem('cda'),
              correo: '',
            }}
            validationSchema={validacionDeFormulario}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })
              }, 0)
              sessionStorage.setItem('correo', values.correo)
              navigate('/activacion/finalizada')
            }}
          >
            {({ handleSubmit, errors }) => (
              <Form
                className='d-flex justify-content-center align-items-center flex-column'
                onSubmit={handleSubmit}
              >
                {/* Dui */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'dui'}>Número de DUI</label>
                    <Field
                      type={'text'}
                      id={'dui'}
                      name={'dui'}
                      data-placeholder=''
                      autoComplete='off'
                      readOnly
                      style={{ backgroundColor: '#e6b8f126' }}
                    />
                    {errors.dui ? (
                      <div className='message-error'>{errors.dui}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Nombres */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'nombres'}>Nombres</label>
                    <Field
                      type={'text'}
                      id={'nombres'}
                      name={'nombres'}
                      data-placeholder=''
                      autoComplete='off'
                      readOnly
                      style={{ backgroundColor: '#e6b8f126' }}
                    />
                    {errors.nombres ? (
                      <div className='message-error'>{errors.nombres}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Apellidos */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'apellidos'}>Apellidos</label>
                    <Field
                      type={'text'}
                      id={'apellidos'}
                      name={'apellidos'}
                      data-placeholder=''
                      autoComplete='off'
                      readOnly
                      style={{ backgroundColor: '#e6b8f126' }}
                    />
                    {errors.apellidos ? (
                      <div className='message-error'>{errors.apellidos}</div>
                    ) : null}
                  </div>
                </Col>

                {/* dirección */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'direccion'}>Dirección</label>
                    <Field
                      type={'text'}
                      id={'direccion'}
                      name={'direccion'}
                      data-placeholder=''
                      autoComplete='off'
                      readOnly
                      style={{ backgroundColor: '#e6b8f126' }}
                    />
                    {errors.direccion ? (
                      <div className='message-error'>{errors.direccion}</div>
                    ) : null}
                  </div>
                </Col>

                {/* fecha */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='row form-group'>
                    <div className='float-container float-container-form active'>
                      <div className='input-group date active' id='datepicker'>
                        <label htmlFor={'fecha'}>{'Fecha de nacimiento'}</label>
                        <DatePicker
                          className='form-control'
                          id={'fecha'}
                          name={'fecha'}
                          selected={startDate}
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) => setStartDate(date)}
                          ref={datePickerRef}
                          showMonthDropdown
                          showYearDropdown
                          readOnly
                        />
                        <span
                          className='input-group-append datepicker-icon'
                          onClick={() => datePickerRef.current.setOpen(true)}
                        >
                          <span className='input-group-text bg-white d-block'>
                            <IconCalendar />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input type='hidden' id='fecharesult' value='' />
                  </div>
                </Col>

                {/* Correo */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'correo'}>Correo</label>
                    <Field
                      type={'text'}
                      id={'correo'}
                      name={'correo'}
                      data-placeholder=''
                      autoComplete='off'
                    />
                    {errors.correo ? (
                      <div className='message-error'>{errors.correo}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Numero CDA */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className='float-container float-container-form'>
                    <label htmlFor={'cda'}>Número CDA del chip Movistar</label>
                    <Field
                      type={'text'}
                      id={'cda'}
                      name={'cda'}
                      data-placeholder=''
                      maxLength={8}
                      autoComplete='off'
                      readOnly
                      style={{ backgroundColor: '#e6b8f126' }}
                    />
                    {errors.cda ? (
                      <div className='message-error'>{errors.cda}</div>
                    ) : null}
                  </div>
                </Col>

                <br />
                <button type='submit' className='btn_verde mb-3'>
                  Finalizar activación
                </button>
                <button
                  type='button'
                  className='btn-azul-oscuro btn-space mb-5'
                  onClick={capturarDatos}
                >
                  Tomar fotos nuevamente
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default FormValidacionActivacion
