import { IconXboxX } from "@tabler/icons-react";

import Header from "../../components/Header";
import { Link } from "react-router-dom";

function PageErrorVerificacion() {
  return (
    <div>
        <Header subT={"Verificando identidad"} />
        <div className="container menu">
            <IconXboxX
                color="#019df4"
                size={120}
                className="tarjeta__imagen"
            />
        </div>
        <div className="contenedor-informacion">
            <div className="contenedor-info-extra">
            <p>
                Juan Perez no hemos podido validar tu identidad
            </p>
            </div>
            <div className="contenedor-botones">
            <Link to={'/'} type="submit" className="btn-azul-oscuro btn-space mb-3">
                Aceptar
            </Link>
            </div>
        </div>
    </div>
  )
}

export default PageErrorVerificacion