import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../components/baseURL";
import Header from "../../components/Header";
import Table from 'react-bootstrap/Table';
import { BsFillPencilFill, BsFillTrashFill, BsPlusSquare, BsReplyFill } from "react-icons/bs";
import FormCanal from "../../components/mantenimiento/canal/FormCanal";
import FormCanalEdit from "../../components/mantenimiento/canal/FormCanalEdit";
import FormCanalDelete from "../../components/mantenimiento/canal/FormCanalDelete";

const PageHomeCanal = () => {

  const [channelList, setChannelList] = useState([]);
  const [showModalCanal, setModalCanal] = useState([]);
  const [showModalEditCanal, setModalEditCanal] = useState([]);
  const [showModalDeleteCanal, setModalDeleteCanal] = useState([]);

  // Solicitud AXIOS de Canales
  const getChannel = async () => {
    await axios
      .get(`${baseUrl.url}channel`)
      .then((response) => {
        if (response.data.status === 200) {
          setChannelList(response.data.data)
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const showModal = () => setModalCanal(<FormCanal />);
  const showEditModal = (data) => setModalEditCanal(<FormCanalEdit data={data} />);
  const showDeleteModal = (data) => setModalDeleteCanal(<FormCanalDelete data={data} />);

  useEffect(() => {
    getChannel();
  }, [])

  let showUser = false;
  if (JSON.parse(sessionStorage.getItem('dataUser')).roleUser !== "VENDEDOR") {
    showUser = true;
  }

  if (showUser) {
    return (
      <div>
        <Header title={"Mantenimiento de Canales"} />
        <div className="container">
          <div className="row text-center mb-3">
            <div className="col-md-4 col-sm-12"><Link to={"/dashboard"}><label className="btn btn-secondary border-boton"><BsReplyFill /> VOLVER</label></Link></div>
            <div className="col-md-4 col-sm-12"><span><hr /></span></div>
            <div className="col-md-4 col-sm-12"><label className="btn btn-success border-boton" onClick={showModal}><BsPlusSquare /> AGREGAR</label></div>
          </div>
          <div className="row">
            <Table responsive striped bordered hover variant="light">
              <thead>
                <tr className="text-center">
                  <th>NOMBRE</th>
                  <th>DETALLE</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                {
                  channelList.map((data, i) => (
                    <tr key={i} className="text-center">
                      <td>{data.nombre}</td>
                      <td>{data.detalle}</td>
                      <td>
                        <label className="btn btn-warning" onClick={() => showEditModal(data)}><BsFillPencilFill /></label>
                        <span> | </span>
                        <label className="btn btn-danger" onClick={() => showDeleteModal(data)}><BsFillTrashFill /></label>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        </div>

        <div>
          {showModalCanal}
        </div>
        <div>
          {showModalEditCanal}
          {showModalDeleteCanal}
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div>
          <Header title={"Mantenimiento de Canales"} />
        </div>

        <div className="text-center"> 
        <p>No tienes permiso para acceder a esta pagina </p>
        <Link to="/">
          <button className="btn_verde btn-lg btn-block border-boton mb-3"> Volver a inicio </button>
        </Link>
        
        </div>
      </>
    );
  }


}

export default PageHomeCanal