import Header from '../../components/Header'
import FormSolicitudReingresoPorta from '../../components/registroActividad/FormSolicitudReingresoPorta'

const PageSolicitudReingresoPorta = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Reingreso de Portación'}
      />
      <FormSolicitudReingresoPorta />
    </div>
  )
}

export default PageSolicitudReingresoPorta
