import { Col, Form, FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { crearPdfFinal } from "../../services/crearPDFHome";
import loading from "../../img/Loading.gif";


function FormConsultaHomeuser() {
    const user = JSON.parse(sessionStorage.getItem("data_perfilamiento"));
    const monto = JSON.parse(sessionStorage.getItem("selectedOffer"));
    const pdf = sessionStorage.getItem("tempPDF");
    const navigate = useNavigate();
    const montoElegido = `[ ${monto.oferta} ] - Monto Maximo $ ${monto.limitecredito}`;

    const handleCancel = () => {
        navigate("/");
    }

    /* TRUE si esta cargando, FALSE si no esta cargando */
    const isLoading = (status) => {
        document.getElementById("loading").hidden = !status;
        document.getElementById("btn-Cancelar").hidden = status;
        document.getElementById("btn-Enviar").hidden = status;
        document.getElementById("info-success").hidden = status;
        document.getElementById("info-error").hidden = status;
    }

    const handleSave = async (e) => {
        e.preventDefault();
        isLoading(true);
        user.monto = montoElegido;
        user.pdf = pdf;
        try {
            const responsePDF = await crearPdfFinal(user);

            if (responsePDF.status === 200) {
                console.log("Se ha guardado el PDF");
                document.getElementById("loading").hidden = true;
                document.getElementById("info-success").hidden = false;

                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
        } catch (error) {
            console.log("No se pudo guardar el PDF");
            document.getElementById("loading").hidden = true;
            document.getElementById("info-error").hidden = false;
            document.getElementById("btn-Cancelar").hidden = false;
            document.getElementById("btn-Enviar").hidden = false;
        }
    }

    return (
        <div className="container h-100">
            <h3 className="text-center">Resumen Informacion</h3>
            <div className="col-md-12">
                <Form className="d-flex justify-content-center align-items-center flex-column">
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"nombres"}>Nombres</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.nombres} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"dui"}>DUI</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.num_doc} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"departamento"}>Departamento</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.nombre_dep} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"municipio"}>Municipio</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.nombre_mun} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"direccion"}>Direccion</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.direccion} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"correo"}>Correo electronico</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.correo} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"numero"}>Numero contacto</label>
                            <FormControl
                                type="text"
                                disabled
                                value={user.contacto} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                        <div className="float-container float-container-form">
                            <label htmlFor={"oferta"}>Monto Pre Aprobado</label>
                            <FormControl
                                type="text"
                                disabled
                                value={montoElegido} />
                            <div
                                className="message-error"
                                id="message-error"
                                hidden
                            ></div>
                        </div>
                    </Col>
                    <img src={loading} alt="validando-informacion" id="loading" hidden />
                    <Col xs={12} sm={12} md={9} lg={5} xl={4} className="mt-4">
                        <div className="alert alert-success" role="alert" id="info-success" hidden>
                            La informacion ha sido guardada exitosamente
                        </div>
                        <div className="alert alert-danger" role="alert" id="info-error" hidden>
                            Ocurrio un error, reintenta nuevamente
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={5} xl={4} className="d-flex justify-content-between m-auto px-2 mt-4">
                        <button id="btn-Cancelar" onClick={handleCancel} className="btn_verde mb-5">
                            Cancelar
                        </button>

                        <button id="btn-Enviar" onClick={handleSave} className="btn_verde mb-5">
                            Enviar
                        </button>
                    </Col>
                </Form>
            </div>
        </div>
    );
}


export default FormConsultaHomeuser; 