import axios from "axios";
import Header from "../../components/Header";
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { baseUrl } from "../../components/baseURL";
import Select from 'react-select';
import FormUsuario from "../../components/mantenimiento/usuarios/FormUsuario";
import FormUsuarioEdit from "../../components/mantenimiento/usuarios/FormUsuarioEdit";
import FormUsuarioConfg from "../../components/mantenimiento/usuarios/FormUsuarioConfg";
import FormUsuarioRol from "../../components/mantenimiento/usuarios/FormUsuarioRol";
import FormUsuarioDelete from "../../components/mantenimiento/usuarios/FormUsuarioDelete";
import { Link } from "react-router-dom";
import { BsFillPencilFill, BsFillTrashFill, BsFillPeopleFill, BsPlusSquare, BsPersonFillGear, BsReplyFill, BsGearFill } from "react-icons/bs";
import "../css/test.css";


const PageHomeUsuario = () => {
  const initialState = {
    canal: "",
    cm: "",
    dp: "",
    rol: "",
    estado: ""
  }

  const [showModalUsuario, setModalUsuario] = useState([]);
  const [showModalEditUsuario, setModalEditUsuario] = useState([]);
  const [showModalConfgUsuario, setModalConfgUsuario] = useState([]);
  const [showModalRolUsuario, setModalRolUsuario] = useState([]);
  const [showModalDeleteUsuario, setModalDeleteUsuario] = useState([]);

  const [rolList, setRolList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [marketerList, setMarketerList] = useState([]);
  const [dpList, setDpList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [query, setQuery] = useState(initialState);
  const columns = [
    {
      name: 'CODIGO',
      selector: (row) => row.codVendedor,
      sortable: true,
      className: 'd-none'
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      minWidth: '250px',
    },
    {
      name: 'CORREO',
      selector: (row) => row.correo,
      sortable: true,
    },
    {
      name: 'TELEFONO',
      selector: (row) => row.telefono,
      sortable: true,
    },
    {
      name: 'CANAL',
      selector: (row) => row.canal,
      sortable: true,
    },
    {
      name: 'COMERCIALIZADOR',
      selector: (row) => row.comercializador,
      sortable: true,
    },
    {
      name: 'DEPARTAMENTO',
      selector: (row) => row.departamento,
      sortable: true,
    },
    {
      name: 'ROL',
      selector: (row) => row.rol,
      sortable: true,
      minWidth: '220px',
    },
    {
      name: 'ACCIONES',
      minWidth: '200px',
      cell: (row) => (
        <>
          <button className="btn btn-warning" onClick={() => showEditModal(row)}> <BsFillPencilFill /> </button>
          <span> | </span>
          <button className="btn btn-info" onClick={() => showRolModal(row)}> <BsPersonFillGear /> </button>
          <span> | </span>
          <button className="btn btn-success" onClick={() => showConfgModal(row)}> <BsGearFill /> </button>
          <span> | </span>
          <button className="btn btn-danger" onClick={() => showDeleteModal(row)}> <BsFillTrashFill /> </button>
        </>
      ),
    },
  ];

  // Obtiene y muestra los usuarios segun los filtros seleccionados
  useEffect(() => {
    if (query.rol || query.estado || query.canal || query.cm || query.dp) {
      getUsersFilter();
    } else {
      getUsers();
    }
  }, [query]);

  // Cambia el estado del query params que se envia en la url de consulta segun filtros 
  const handleSelectChange = (fieldName) => (selectedOption) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: selectedOption ? selectedOption.value : "",
    }));
  };

  // Obtiene los ultimos 20 usuarios registrados
  const getUsers = async () => {
    await axios
      .get(`${baseUrl.url}usuario`)
      .then((response) => {
        if (response.data.status === 200) {
          setUserList(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };


  const getRoles = async () => {
    await axios
      .get(`${baseUrl.url}usuario/roles`)
      .then((response) => {
        if (response.data.status === 200) {
          const formattedData = [
            { value: '', label: 'Seleccionar rol...' },
            ...response.data.data.map((rol) => ({
              value: rol.id,
              label: rol.nombre,
            })),
          ];
          setRolList(formattedData);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const getStates = async () => {
    await axios
      .get(`${baseUrl.url}usuario/estados`)
      .then((response) => {
        if (response.data.status === 200) {
          const formattedData = [
            { value: '', label: 'Seleccionar estado...' },
            ...response.data.data.map((state) => ({
              value: state.id,
              label: state.nombre,
            })),
          ];
          setStateList(formattedData);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const getChannel = async () => {
    await axios
      .get(`${baseUrl.url}channel`)
      .then((response) => {
        if (response.data.status === 200) {
          const formattedData = [
            { value: '', label: 'Seleccionar canal...' },
            ...response.data.data.map((channel) => ({
              value: channel.id,
              label: channel.nombre,
            })),
          ];
          setChannelList(formattedData);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const getMarketer = async () => {
    await axios
      .get(`${baseUrl.url}cm`)
      .then((response) => {
        if (response.data.status === 200) {
          const formattedData = [
            { value: '', label: 'Seleccionar comercializador...' },
            ...response.data.data.map((cm) => ({
              value: cm.id,
              label: cm.nombre,
            })),
          ];
          setMarketerList(formattedData);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  }

  const getDp = async () => {
    await axios
      .get(`${baseUrl.url}dp`)
      .then((response) => {
        if (response.data.status === 200) {
          const formattedData = [
            { value: '', label: 'Seleccionar departamento...' },
            ...response.data.data.map((dp) => ({
              value: dp.id,
              label: dp.nombre,
            })),
          ];
          setDpList(formattedData);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  }

  // Obtiene los valores iniciales para poder seleccionar los datos a filtrar
  useEffect(() => {
    getRoles();
    getStates();
    getChannel();
    getMarketer();
    getDp();
  }, []);


  // Obtiene los datos de los usuarios filtrados segun el query por canal, cm, dp, rol, estado
  const getUsersFilter = async () => {
    try {
      const qry = Object.entries(query)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
      const url = `${baseUrl.url}usuario?${qry}`;

      const response = await axios.get(url);

      if (response.data && response.data.status === 200) {
        setUserList(response.data.data)
      } else if (response.data && response.data.status === 404) {
        setUserList([]);
        console.log(response.data.message);
      }

    } catch (error) {
      console.log(error);
    }
  };


  const showModal = () => setModalUsuario(<FormUsuario cm={marketerList} />);
  const showEditModal = (data) => setModalEditUsuario(<FormUsuarioEdit data={data} />);
  const showConfgModal = (data) => setModalConfgUsuario(<FormUsuarioConfg data={data} cm={marketerList} />);
  const showRolModal = (data) => setModalRolUsuario(<FormUsuarioRol data={data} />);
  const showDeleteModal = (data) => setModalDeleteUsuario(<FormUsuarioDelete data={data} />);

  let showUser = false;
  if (JSON.parse(sessionStorage.getItem('dataUser')).roleUser !== "VENDEDOR") {
    showUser = true;
  }

  if (showUser) {
    return (
      <div>
        <Header title={"Mantenimiento de Usuarios"} />
        <div className="container">
          <div className="row text-center mb-3">
            <div className="col-md-4 col-sm-12"><Link to={"/dashboard"}><label className="btn btn-secondary border-boton"><BsReplyFill /> VOLVER</label></Link></div>
            <div className="col-md-4 col-sm-12"><span><hr /></span></div>
            <div className="col-md-4 col-sm-12"><label className="btn btn-success border-boton" onClick={showModal} ><BsPlusSquare /> AGREGAR</label></div>
          </div>

          <div className="row text-center mb-3 p-2" >
            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Rol</label>
              <Select
                options={rolList}
                isSearchable
                value={rolList.find((option) => option.value === query.rol)}// Necesario para que el componente reaccione a los cambios en las opciones
                placeholder="Rol"
                onChange={handleSelectChange("rol")}
                name="rol"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Estado</label>
              <Select
                options={stateList}
                isSearchable
                value={stateList.find((option) => option.value === query.estado)}// Necesario para que el componente reaccione a los cambios en las opciones
                placeholdexr="Estado"
                onChange={handleSelectChange("estado")}
                name="estado"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Canal</label>
              <Select
                options={channelList}
                isSearchable
                value={channelList.find((option) => option.value === query.canal)}// Necesario para que el componente reaccione a los cambios en las opciones
                placeholder="Canal"
                onChange={handleSelectChange("canal")}
                name="canal"
              />
            </div>

          </div>

          <div className="row text-center mb-3 p-2" >

            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Comercializador</label>
              <Select
                options={marketerList}
                isSearchable
                value={marketerList.find((opt) => opt.value === query.cm)}// Necesario para que el componente reaccione a los cambios en las opciones
                placeholder="Comercializador"
                onChange={handleSelectChange("cm")}
                name="cm"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Departamento</label>
              <Select
                options={dpList}
                isSearchable
                value={dpList.find((opt) => opt.value === query.dp)}
                placeholder="Departamento"
                onChange={handleSelectChange("dp")}
                name="dp"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="exampleFormControlInput1" className="form-label"></label>
              <button className="btn btn-outline-success mt-2" style={{ display: 'block' }} onClick={() => setQuery(initialState)}>Borrar filtros</button>
            </div>
          </div>


          {/* <div className="row">
          <div className="col-12">
            <p className="alert alert-success">Mostrando usuarios inactivos con rol VENDEDOR que pertenecen al canal TIENDA, comercializador GISER, departamento LA PAZ  </p>
          </div>
        </div> */}

          <div className="row">
            <DataTable
              columns={columns}
              data={userList}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20, 50]}
              noDataComponent="No se encontraron registros"
              highlightOnHover
              striped
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página:',
                rangeSeparatorText: 'de',
                selectAllRowsItem: true,
                selectAllRowsItemText: 'Todos',
              }}
            />
          </div>
        </div>
        {showModalUsuario}
        <div>
          {showModalEditUsuario}
          {showModalConfgUsuario}
          {showModalRolUsuario}
          {showModalDeleteUsuario}
        </div>
        <div>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div>
          <Header title={"Mantenimiento de Usuarios"} />
        </div>

        <div className="text-center">
          <p>No tienes permiso para acceder a esta pagina </p>
          <Link to="/">
            <button className="btn_verde btn-lg btn-block border-boton mb-3"> Volver a inicio </button>
          </Link>

        </div>
      </>
    );
  }


}

export default PageHomeUsuario;