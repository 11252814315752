import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import { statusOcr } from '../../services/StatusOcrServices'
import loading from '../../img/Loading.gif'
import { CreateUuid, obtenerIdGestion } from '../../services/DbServices'

const PageCargaRecuperacion = () => {
  const [idGestion, setIdGestion] = useState()
  const navigate = useNavigate()
  var estado = true
  var respueta = ''

  function validarOcr() {
    const valores = window.location.search
    const urlParams = new URLSearchParams(valores)
    var uid = urlParams.get('uid')
    sessionStorage.setItem('uid', uid)

    const data = {
      uuid: uid,
      trx: sessionStorage.getItem('trx'),
      paso: 2,
    }
    const datosCodificado = encodeURIComponent(JSON.stringify(data))
    statusOcr(uid).then((response) => {
      if (
        response['status'] === 'TERMINATED' ||
        response['status'] === 'ERROR'
      ) {
        estado = false
        respueta = response['status']
      }
    })

    console.log(estado)
    if (estado) {
      setTimeout(() => {
        validarOcr()
      }, '4000')
    } else {
      CreateUuid(datosCodificado).then((response) => {
        if (respueta === 'TERMINATED') {
          navigate('/recuperacion/datos-recuperacion')
        } else {
          navigate('/recuperacion/error-ocr')
        }
      })
    }
  }

  useEffect(() => {
    obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
      setIdGestion(response['id'])
      validarOcr()
    })
  }, [])

  return (
    <div>
      <Header
        subT={'Esperando información del cliente'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <div className='contenedor-informacion contenedor-gif'>
        <img
          src={loading}
          alt='validando-cda'
          id='loading'
          style={{ width: '100px' }}
        />
      </div>
    </div>
  )
}

export default PageCargaRecuperacion
