import { useState } from 'react'

import Header from '../../components/Header'
import { v4 as uuidv4 } from 'uuid'
import { CreateTrxActCom, obtenerIdGestion } from '../../services/DbServices'
import { baseUrl } from '../../components/baseURL'

function PageErrorActivacionOcr() {
  const [idGestion, setIdGestion] = useState()

  function capturarData() {
    const idTrx = uuidv4()

    const data = {
      cda: sessionStorage.getItem('cda'),
      tipo: 1,
      trx: idTrx,
      codVendedor: sessionStorage.getItem('codVendedor'),
      paso: 1,
    }
    sessionStorage.setItem('trx', idTrx)
    const datosCodificado = encodeURIComponent(JSON.stringify(data))
    CreateTrxActCom(datosCodificado).then((response) => {
      window.location.replace(
        baseUrl['uri'] +
          '?idTrx=' +
          idTrx +
          '&tipo=1&idSolic=' +
          response['id'] +
          '&cda=' +
          response['cda'] +
          '&seller=' +
          response['codVendedor']
      )
    })
  }

  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  return (
    <div>
      <Header
        subT={'No podemos procesar la solicitud'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <div className='contenedor-informacion'>
        <div className='cuadro-informacion'>
          <p className='message-error-label'>
            Los datos no fueron capturados correctamente, debes repetir el
            proceso
          </p>
        </div>
        <div className='contenedor-botones'>
          <button onClick={capturarData} className='btn-azul-oscuro'>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  )
}

export default PageErrorActivacionOcr
