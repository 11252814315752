import * as Yup from "yup";

export const validacionDeFormulario = Yup.object().shape({
    nombres: Yup.string()
        .min(10, "El nombre debe tener al menos 10 caracteres")
        .required("El nombre completo es requerido"),

    /* num_doc: Yup.string()
        .matches(/^\d{8}-\d{1}$|^\d{4}-\d{6}-\d{3}-\d{1}$/, {
            message: "Formato inválido. Debe ser 12345678-9 o 1234-567890-123-4",
        })
        .required("El campo DUI/NIT es requerido"), */

    num_doc: Yup.string()
        .matches(/^\d{8}-\d{1}$/, {
            message: "Formato inválido. Debe ser 00000000-0",
        })
        .required("El campo DUI es requerido"),

    departamento: Yup.string().required("Departamento es requerido"),

    nombre_dep: Yup.string().required("El nombre del departamento es requerido"),

    municipio: Yup.string().required("Municipio es requerido"),

    nombre_mun: Yup.string().required("El nombre del municipio es requerido"),

    direccion: Yup.string()
        .min(10, "La dirección debe tener al menos 10 caracteres")
        .required("La dirección de instalación es requerida"),

    correo: Yup.string()
        .email("Correo electrónico no válido")
        .matches(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            "Correo electrónico no válido"
        )
        .required("Debes ingresar un correo válido"),

    contacto: Yup.string()
        .matches(/^\d{8}$/, "Formato de número de contacto inválido. Debe ser XXXXXXXX")
        .required("Número de contacto es requerido")

});