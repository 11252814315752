import Header from '../../components/Header'
import FormRegistroActividad from '../../components/registroActividad/FormRegistroActividad'

const PageRegistroActividad = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Resumen de Actividad en la Web'}
      />
      <FormRegistroActividad />
    </div>
  )
}

export default PageRegistroActividad
