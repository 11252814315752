import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function crearPdf(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}crear-pdf`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
