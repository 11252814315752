import Header from '../../components/Header'
import FormDetalleActividadActivacionesMensual from '../../components/registroActividad/FormDetalleActividadActivacionesMensual'

const PageDetalleActividadMensual = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Activaciones en la Web este Mes'}
      />
      <FormDetalleActividadActivacionesMensual />
    </div>
  )
}

export default PageDetalleActividadMensual
