import Header from '../../components/Header'
import FormDetalleActividadPortaEfectiva from '../../components/registroActividad/FormDetalleActividadPortaEfectiva'

const PageDetalleActividadPortaEfectiva = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Portaciones Efectivas en la Web'}
      />
      <FormDetalleActividadPortaEfectiva />
    </div>
  )
}

export default PageDetalleActividadPortaEfectiva
