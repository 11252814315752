import Header from '../../components/Header'
import FormConsultaHome from '../../components/consultaHome/FormConsultaHome';

const PageConsultaHome = () => {
    return (
        <div>
          <Header
            title={'Internet Hogar'}
          />
          <FormConsultaHome/>
        </div>
        
      )
}

export default PageConsultaHome; 