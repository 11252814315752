import { Formik, Field } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form, Col } from "react-bootstrap";
import ModalObtenerCda from "../ModalObtenerCda";
import { validarCda } from "../../services/ValidarCdaServices";
import loading from "../../img/Loading.gif";
import { v4 as uuidv4 } from "uuid";
import { baseUrl } from "../baseURL";

import { FloatLabel } from "../../js/inputs";
import { CreateTrxActCom } from "../../services/DbServices";

// Valido todos los campos del formulario
const validacionDeFormularioHome = Yup.object().shape({
  cda: Yup.string()
    .min(8, "Número de CDA debe contener 8 digitos")
    .max(8, "Número de CDA debe contener 8 digitos")
    .matches(/^\d{8}/, "Ingrese número de CDA")
    .required("Número de CDA inválido"),
});

function errorHidden() {
  document.getElementById("cda-invalido").hidden = true;
}

// inicio el script para las animaciiones de los input
function FormHomeRecuperacion() {
  const idTrx = uuidv4();
  useEffect(() => {
    FloatLabel.init();
  });
  return (
    <div className="container h-100">
      <div className="col-md-12">
        {/* props : css, type, mLength */}
        <Formik
          // inicializando los valores de los input
          initialValues={{
            cda: "",
          }}
          validationSchema={validacionDeFormularioHome}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            document.getElementById("btn-continuar").hidden = true;
            document.getElementById("loading").hidden = false;

            validarCda(values.cda)
              .then((response) => {
                if (response["_text"] === "true") {
                  sessionStorage.setItem("cda", values.cda);
                  const data = {
                    cda: values.cda,
                    tipo: 3,
                    trx: idTrx,
                    codVendedor: sessionStorage.getItem("codVendedor"),
                    paso: 1,
                  };
                  sessionStorage.setItem('trx',idTrx);
                  const datosCodificado = encodeURIComponent(
                    JSON.stringify(data)
                  );
                  CreateTrxActCom(datosCodificado).then((response) => {
                    console.log(response);
                    window.location.replace(
                      baseUrl['uri'] + "?idTrx=" +
                        idTrx +
                        "&tipo=3&idSolic=" +
                        response["id"] +
                        "&cda=" +
                        response["cda"] +
                        "&seller=" +
                        response["codVendedor"]
                    );
                  });
                } else {
                  document.getElementById("cda-invalido").hidden = false;
                  document.getElementById("btn-continuar").hidden = false;
                  document.getElementById("loading").hidden = true;
                }
              })
              .catch((error) => {
                alert("Error de conexion, intenta nuevamente");
                document.getElementById("btn-continuar").hidden = false;
                document.getElementById("loading").hidden = true;
              });
          }}
        >
          {({ handleSubmit, errors }) => (
            <Form
              className="d-flex justify-content-center align-items-center flex-column"
              onSubmit={handleSubmit}
            >
              {/* CDA */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                <div className="float-container float-container-form">
                  <label htmlFor={"cda"}>Número CDA</label>
                  <Field
                    type={"text"}
                    id={"cda"}
                    name={"cda"}
                    data-placeholder=""
                    autoComplete="off"
                  />
                  {<errors className="cda"></errors> ? (
                    <div className="message-error">{errors.cda}</div>
                  ) : null}
                  <div className="message-error" id="cda-invalido" hidden>
                    Número de CDA inválido
                  </div>
                </div>
              </Col>

              {/* Modal como encontrar cda */}
              <Col xs={12} sm={12} md={9} lg={5} xl={4} className="ml-2">
                <div className="enlaces funcionamiento">
                  <div className="p-2 align-self-center funcionamiento">
                    <ModalObtenerCda />
                  </div>
                </div>
              </Col>

              <br />
              <button
                type="submit"
                className="btn_verde mb-4"
                id="btn-continuar"
                onClick={errorHidden}
              >
                Continuar
              </button>
              <img src={loading} alt="validando-cda" id="loading" hidden />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FormHomeRecuperacion;
