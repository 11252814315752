import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { crearPdf } from "../services/crearPdf";
import loading from "../img/Loading.gif";

const Firma = () => {
  const navigate = useNavigate();
  const signatureRef = useRef(null);
  const [error, setError] = useState(false);

  const saveSignature = () => {
    if (signatureRef.current) {
      const canvas = signatureRef.current.getCanvas();
      const imageDataUrl = canvas.toDataURL("image/png");
      return imageDataUrl;
    }
    return null;
  };

  const getImage = () => {
    document.getElementById("btn-Enviar").hidden = true;
    document.getElementById("btn-Reintentar").hidden = true;
    document.getElementById("loading").hidden = false;

    const imageDataUrl = saveSignature();
    if (imageDataUrl) {
      const canvasWidth = 350;
      const canvasHeight = 250;
      const image = new Image();
      image.src = imageDataUrl;
      image.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
        const finalImageDataUrl = canvas.toDataURL("image/png");
        const duiSinGuion = sessionStorage.getItem('dui');
        var ultimoNumero = duiSinGuion.slice(-1);
        var duiGuion = duiSinGuion.slice(0, -1) + "-" + ultimoNumero;
        
        const objeto = {
          imagen: finalImageDataUrl,
          uid: sessionStorage.getItem("uid"),
          nombre: sessionStorage.getItem('nombre') + ' ' + sessionStorage.getItem('apellido'),
          dui: duiGuion,
          tipoDocumento: 'DUI',
          nit: duiGuion,
          correo: sessionStorage.getItem('correo'),
          telContacto: sessionStorage.getItem('telContacto'),
          departamento: sessionStorage.getItem('departamento'),
          municipio: sessionStorage.getItem('municipio'),
          direccion: sessionStorage.getItem('direccion'),
          numeros: sessionStorage.getItem('telefono'),
          CIPS: sessionStorage.getItem('cip'),
          compania: sessionStorage.getItem('compania')
        };
        const datosCodificado = encodeURIComponent(JSON.stringify(objeto));

        const responsePDF = await crearPdf(datosCodificado);

        if (responsePDF["status"] === 200) {
          document.getElementById("btn-Enviar").hidden = false;
          document.getElementById("btn-Reintentar").hidden = false;
          document.getElementById("loading").hidden = true;
          sessionStorage.setItem("pdf", responsePDF["pdf"]);
          navigate("/portabilidad/finalizada");
        } else {
          document.getElementById("btn-Enviar").hidden = false;
          document.getElementById("btn-Reintentar").hidden = false;
          document.getElementById("loading").hidden = true;
          alert("No se pudo crear el pdf, reintentar");
        }

        console.log(responsePDF);

        // const link = document.createElement("a");
        // link.href = finalImageDataUrl;
        // link.download = "firma.png";
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      };
    } else {
      setError(true);
    }
  };

  return (
    <div className="contenedor-firma">
      <SignatureCanvas
        canvasProps={{
          width: 350,
          height: 250,
          className: "firma",
        }}
        ref={signatureRef}
        onBegin={() => {
          setError(false);
        }}
      />
      <pre className="mt-1" id="error-firma" hidden>
        {error && <div className="message-error">La firma es obligatoria</div>}
      </pre>
      <button
        className="btn-blanco-azul mt-3 mb-3"
        id="btn-Reintentar"
        onClick={() => {
          if (signatureRef.current) {
            signatureRef.current.clear();
            setError(false);
          }
        }}
      >
        Reintentar
      </button>
      <button onClick={getImage} id="btn-Enviar" className="btn_verde mb-5">
        Enviar solicitud
      </button>
      <img src={loading} alt="validando-firma" id="loading" hidden />
    </div>
  );
};

export default Firma;
