import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function perfilamiento(datos) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrl.url}perfilamiento`, { data: datos })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function obtenreInfoCliente(telefono) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl.url}obtener-info-cliente/${telefono}`)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
