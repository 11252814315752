import Header from '../../components/Header';
import { Navigate } from 'react-router-dom';
import FormConsultaHomeOferta from "../../components/consultaHome/FormConsultaHomeOferta";

const PageConsultaHomeOferta = () => {
  const data = sessionStorage.getItem("data_perfilamiento");

  if (!data) {
    return <Navigate to="/consultahome" />;
  }

  return (
    <div>
      <Header
        title={'Internet Hogar'}
      />
      <FormConsultaHomeOferta />
    </div>
  );
}

export default PageConsultaHomeOferta;

