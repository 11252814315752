import { createContext, useContext, useEffect, useState } from "react";
import { FloatLabel } from "../../js/inputs";
import axios from 'axios';
import { baseUrl } from '../baseURL';
import imgLoading from "../../img/Loading.gif";
import db from "../../helpers/indexedDBHelper";

// inicio el script para las animaciones de los input
function FormRegistroActividad() {
  const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
  const [codVendedor] = useState(sessionDate.codigoUser);
  let hasCalledEstadoPorta = false;
  const TIMEOUT_DURATION = 2000;
  // TYPE TRANSACTION
  const [tipoActivacion] = useState(1);
  const [tipoPortabilidad] = useState(2);
  const [tipoMantenimiento] = useState(3);

  // PORTACIONES DATA
  const [portacionesDataOneWeek, setPortacionesDataOneWeek] = useState([]);
  const [portacionesDataMonth, setPortacionesDataMonth] = useState([]);

  // VALIDACION DE CARGA DE DATOS
  // 0. Error al obtener los datos, 1. Datos cargando 2. Datos cargados
  const [status, setStatus] = useState(1);
  const [statusM, setStatusM] = useState(1);

  // ONE WEEK
  const [activacionesOneWeek, setActivacionesOneWeek] = useState(0);
  const [mantenimientoOneWeek, setMantenimientoOneWeek] = useState(0);
  const [portacionesEfectivasOneWeek, setPortacionesEfectivasOneWeek] = useState(0);
  const [portacionesNoEfectivasOneWeek, setPortacionesNoEfectivasOneWeek] = useState(0);

  // CURRENT MONTH
  const [activacionesCurrentMonth, setActivacionesCurrentMonth] = useState(0);
  const [mantenimientoCurrentMonth, setMantenimientoCurrentMonth] = useState(0);
  const [portacionesEfectivasCurrentMonth, setPortacionesEfectivasCurrentMonth] = useState(0);
  const [portacionesNoEfectivasCurrentMonth, setPortacionesNoEfectivasCurrentMonth] = useState(0);

  useEffect(() => {
    FloatLabel.init();
    ResumenAllPortaOneWeek();
    ResumenAllPortaMonth(); 
    ResumenActivacionesOneWeek();
    ResumenMantenimientoOneWeek();
    ResumenActivacionesCurrentMonth();
    ResumenMantenimientoCurrentMonth();
  }, []);


  useEffect(() => {
    if (status !== 0) {
      setDataPortacionesOneWeek();
    }
  }, [portacionesDataOneWeek]);

  useEffect(() => {
    if (status !== 0) {
      setDataPortacionesMonth();
    }
  }, [portacionesDataMonth]);


  const ResumenAllPortaOneWeek = async () => {
    await axios
      .get(`${baseUrl.url}actividad/oneweek/porta`, {
        params: {
          codVendedor
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          generarDataUpdate(response.data.data)
            .then((dataUpdateFiltered) => {
              if (dataUpdateFiltered === null) {
                setStatus(0);
              } else {
                setStatus(2);
                setPortacionesDataOneWeek(dataUpdateFiltered);
              }
            })
            .catch((error) => {
              console.error('Error al establecer los detalles: ', error);
            });

        } else {
          console.log(response.data.message);
          setStatus(2);
        }
      })
      .catch((error) => console.log(error));
  };


  const ResumenAllPortaMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth/porta`, {
        params: {
          codVendedor
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          generarDataUpdate(response.data.data)
            .then((dataUpdateFiltered) => {
              if (dataUpdateFiltered === null) {
                setStatusM(0);
              } else {
                setStatusM(2);
                setPortacionesDataMonth(dataUpdateFiltered);
              }
            })
            .catch((error) => {
              console.error('Error al establecer los detalles: ', error);
            });

        } else {
          console.log(response.data.message);
          setStatusM(2);
        }
      })
      .catch((error) => console.log(error));
  };

  const setDataPortacionesOneWeek = async () => {
    try {
      const { finalizado, rechazados } = portacionesDataOneWeek.reduce(
        (result, item) => {
          if (item.estado === 'FINALIZADO_AP') {
            result.finalizado++;
          } else {
            result.rechazados++;
          }
          return result;
        },
        { finalizado: 0, rechazados: 0 }
      );

      setPortacionesEfectivasOneWeek(finalizado);
      setPortacionesNoEfectivasOneWeek(rechazados);
    } catch (error) {
      console.log(error);
    }
  };

  const setDataPortacionesMonth = async () => {
    try {
      const { finalizado, rechazados } = portacionesDataMonth.reduce(
        (result, item) => {
          if (item.estado === 'FINALIZADO_AP') {
            result.finalizado++;
          } else {
            result.rechazados++;
          }
          return result;
        },
        { finalizado: 0, rechazados: 0 }
      );

      setPortacionesEfectivasCurrentMonth(finalizado);
      setPortacionesNoEfectivasCurrentMonth(rechazados);
    } catch (error) {
      console.log(error);
    }
  };


  // Función para verificar si un token ya existe en IndexedDB
  async function getTokenFromIndexedDB(telefono) {
    try {
      const token = await db.tokens.get(telefono);
      return token;
    } catch (error) {
      console.error('Error al obtener el token:', error);
      return null;
    }
  }

  // Función para guardar un token en IndexedDB
  async function saveTokenToIndexedDB(telefono, token) {
    try {
      await db.tokens.put({ telefono, token });
    } catch (error) {
      console.error('Error al guardar el token:', error);
    }
  }

  const generarDataUpdate = async (data) => {
    try {
      // OBTIENE PRIMERO TODOS LOS TOKENS PARA POSTERIORMENTE REALIZAR LAS CONSULTAS
      const tokens = await Promise.all(
        data.map(async (dato) => {
          try {
            const { numeroportar } = dato;

            let token = await getTokenFromIndexedDB(numeroportar);

            if (!token) {
              token = await getEstadoPortaTelcaSV(numeroportar);
              await saveTokenToIndexedDB(numeroportar, token.token);
              hasCalledEstadoPorta = true;
            }
            return token.token;
          } catch (error) {
            console.error('Error al obtener el token:', error);
            return null;
          }
        })
      );

      if (hasCalledEstadoPorta) {
        await new Promise((resolve) => setTimeout(resolve, 8000));
      }

      let isNull = false;

      const dataUpdateArray = await Promise.all(
        tokens.map(async (token, index) => {
          try {
            const estado = await getCheckResponse(token);
            if (estado === null || estado === undefined) {
              isNull = true;
            }
            return { ...data[index], estado };
          } catch (error) {
            console.error('Error al obtener el estado de portabilidad:', error);
            return null;
          }
        })
      );

      return isNull ? null : dataUpdateArray;
    } catch (error) {
      console.error('Error general: ', error);
      return [];
    }
  };

  // Validacion Estado Porta TELCASV
  const getEstadoPortaTelcaSV = async (phone) => {
    try {
      const response = await
        axios({
          method: 'get',
          url: `${baseUrl.url}get-status-porta`,
          params: {
            "telefono": phone
          }
        });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // Validacion Estado Porta TELCASV
  const getCheckResponse = async (token) => {
    try {
      const response = await
        axios({
          method: 'post',
          url: `${baseUrl.url}get-check-response`,
          params: {
            "tipo": "CALL_API_MOVISTAR_DATABASE",
            "token": token,
          },
          timeout: 2000,
        });

      return response.data.estado;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Solicitud AXIOS de Activaciones 1 Semana
  const ResumenActivacionesOneWeek = async () => {
    await axios
      .get(`${baseUrl.url}actividad/oneweek`, {
        params: {
          tipo: tipoActivacion,
          codVendedor,
          efectiva: true
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          setActivacionesOneWeek(response.data.data[0].cantidad);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // Solicitud AXIOS de Mantenimiento 1 Semana
  const ResumenMantenimientoOneWeek = async () => {
    await axios
      .get(`${baseUrl.url}actividad/oneweek`, {
        params: {
          tipo: tipoMantenimiento,
          codVendedor,
          efectiva: true
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          setMantenimientoOneWeek(response.data.data[0].cantidad);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // Solicitud AXIOS de Activaciones Mes Corriente
  const ResumenActivacionesCurrentMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth`, {
        params: {
          tipo: tipoActivacion,
          codVendedor,
          efectiva: true
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          setActivacionesCurrentMonth(response.data.data[0].cantidad);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // Solicitud AXIOS de Mantenimiento Mes Corriente
  const ResumenMantenimientoCurrentMonth = async () => {
    await axios
      .get(`${baseUrl.url}actividad/currentmonth`, {
        params: {
          tipo: tipoMantenimiento,
          codVendedor,
          efectiva: true
        }
      })
      .then((response) => {
        if (response.data.status === 200) {
          setMantenimientoCurrentMonth(response.data.data[0].cantidad);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };


  return (
    <div className="container h-100">
      <div className="row">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Resumen Semanal
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul className="list-group">
                  {
                    status === 0 ? (
                      <div className='mensaje-error text-center'>
                        <p>Ocurrió un error al obtener los datos</p>
                        <div className="mt-2">
                          <a href="/registeractivity" className="btn btn-primary btn-sm">Reintentar</a>
                        </div>
                      </div>
                    ) :
                      status === 1 ?
                        (
                          <div className='contenedor-informacion contenedor-gif'>
                            <img
                              src={imgLoading}
                              alt='consultando-estado'
                              id='loading'
                              style={{ width: '100px' }}
                            />
                          </div>
                        ) :
                        (
                          <>
                            <li className="list-group-item">
                              Activaciones <span className="badge bg-secondary">{activacionesOneWeek}</span>
                              {activacionesOneWeek > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/activations'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Portaciones No Efectivas <span className="badge bg-secondary">{portacionesNoEfectivasOneWeek}</span>
                              {portacionesNoEfectivasOneWeek > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/portability/refused'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Portaciones Efectivas <span className="badge bg-secondary">{portacionesEfectivasOneWeek}</span>
                              {portacionesEfectivasOneWeek > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/portability/effective'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Mantenimientos <span className="badge bg-secondary">{mantenimientoOneWeek}</span>
                              {mantenimientoOneWeek > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/maintenance'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>
                          </>
                        )
                  }


                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Resumen Mes en Curso
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul className="list-group">
                  {
                    statusM === 0 ? (
                      <div className='mensaje-error text-center'>
                        <p>Ocurrió un error al obtener los datos</p>
                        <div className="mt-2">
                          <a href="/registeractivity" className="btn btn-primary btn-sm">Reintentar</a>
                        </div>
                      </div>
                    ) :
                      statusM === 1 ?
                        (
                          <div className='contenedor-informacion contenedor-gif'>
                            <img
                              src={imgLoading}
                              alt='consultando-estado'
                              id='loading'
                              style={{ width: '100px' }}
                            />
                          </div>
                        ) :
                        (
                          <>
                            <li className="list-group-item">
                              Activaciones <span className="badge bg-secondary">{activacionesCurrentMonth}</span>
                              {activacionesCurrentMonth > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/activations/currentmonth'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Portaciones No Efectivas <span className="badge bg-secondary">{portacionesNoEfectivasCurrentMonth}</span>
                              {portacionesNoEfectivasCurrentMonth > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/portability/refused/currentmonth'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Portaciones Efectivas <span className="badge bg-secondary">{portacionesEfectivasCurrentMonth}</span>
                              {portacionesEfectivasCurrentMonth > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/portability/effective/currentmonth'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>

                            <li className="list-group-item">
                              Mantenimientos <span className="badge bg-secondary">{mantenimientoCurrentMonth}</span>
                              {mantenimientoCurrentMonth > 0 ? (
                                <a className="btn btn-primary btn-sm float-end" href='/detailactivity/maintenance/currentmonth'>Ver Detalle</a>
                              ) : (
                                <button className="btn btn-primary btn-sm float-end" disabled>Ver Detalle</button>
                              )}
                            </li>
                          </>

                        )
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default FormRegistroActividad;