import { useState } from 'react'

import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { obtenerIdGestion } from '../../services/DbServices'

function PageErrorActivacion() {
  const [idGestion, setIdGestion] = useState()

  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  return (
    <div>
      <Header
        subT={'No podemos procesar la solicitud'}
        color={'#f00'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <div className='contenedor-informacion'>
        <div className='cuadro-informacion'>
          <p className='message-error-label'>
            El cliente tiene el máximo número de líneas permitidas para un
            usuario prepago.
          </p>
        </div>
        <div className='contenedor-botones'>
          <Link
            to={'/'}
            type='submit'
            className='btn-azul-oscuro btn-space mb-3'
          >
            Aceptar
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageErrorActivacion
