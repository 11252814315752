import Banner from '../components/Banner'
import Opciones from '../components/Opciones'

const PageMenu = () => {
  return (
    <div>
      <div>
        <Banner />
        <Opciones />
      </div>
    </div>
  )
}

export default PageMenu
