import { useState } from 'react';

/*
* Cambiar de SessionStorage a LocalStorage
* Cambie cada referencia de sessionStorage a localStorage
*/
export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('dataUser');
        const dataToken = JSON.parse(tokenString);
        const expireToken = 2880; //Tiempo en que debe expirar el Token en Minutos (60MINUTOS * 48HORAS = 2880 MINUTOS)

        //Tomamos Fecha de Creación del Token
        var dateTokenCreated = new Date(dataToken?.fecha);

        //Creamos un Nuevo objeto de fecha actual
        var Today = new Date();
        var dateNow = new Date(Today.getFullYear(), Today.getMonth(), Today.getDate(), Today.getHours(), Today.getMinutes(), Today.getSeconds());

        //Calculados la firefencia en Minutos
        var dateTokenDie = (dateTokenCreated.getTime() - dateNow.getTime()) / 1000 / 60;

        //Validamos si la diferencia de tiempo
        if (Math.abs(Math.round(dateTokenDie)) >= expireToken) { // Aquí Iría La Validación Del Tiempo De Vida Del Token
            sessionStorage.removeItem('dataUser');
            const userToken = '';
            return userToken?.token
        } else {
            const userToken = dataToken;
            return userToken?.token
        }

    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        var Today = new Date();
        var dateCreated = Date(Today.getFullYear(), Today.getMonth(), Today.getDate(), Today.getHours(), Today.getMinutes());
        const dataUser = {'token':userToken.data.token, 'fecha': dateCreated.toLocaleString(), 'codigoUser': userToken.data.codigo, 'username': userToken.data.username, 'channelUser': userToken.data.channel, 'cmUser': userToken.data.cm, 'statusUser': userToken.data.status, 'roleUser': userToken.data.role, 'roleId': userToken.data.id_role};
        sessionStorage.setItem('dataUser', JSON.stringify(dataUser));
        setToken(dataUser.token);
    };

    return {
        setToken: saveToken,
        token
    }
}