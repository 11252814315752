import React from "react";
import FormHomePortabilidad from "../../components/validacion/FormHomePortabilidad";
import Header from "../../components/Header";

const PageHomePortabilidad = () => {
  return (
    <div>
      <Header subT={"Datos de la gestión"} />
      <div className="contenedor-informacion">
        <div className="contenedor-info-extra-menor">
          <p>Completa los datos para iniciar la gestión</p>
        </div>
      </div>
      <FormHomePortabilidad />
    </div>
  );
};

export default PageHomePortabilidad;
