import Header from '../../components/Header'
import FormDetalleActividadPortaNoEfectiva from '../../components/registroActividad/FormDetalleActividadPortaNoEfectiva'

const PageDetalleActividadPortaNoEfectiva = () => {

  return (
    <div>
      <Header
        title={'Registro de Actividad'}
        subT={'Detalle de Portaciones No Efectivas en la Web'}
      />
      <FormDetalleActividadPortaNoEfectiva />
    </div>
  )
}

export default PageDetalleActividadPortaNoEfectiva
