import { useEffect, useState } from "react";
import { FloatLabel } from "../../js/inputs";
import loading from "../../img/Loading.gif";
import axios from "axios";
import { baseUrl } from "../baseURL";
import { nuevaPortabilidad } from '../../services/NuevaPortabilidadServices'
import { CreateDataSolicitud } from '../../services/DbServices'
import { envioDatosBot } from '../../services/EnvioDatosBot'

// inicio el script para las animaciones de los input
function FormSolicitudReingresoPorta() {

  const sessionDate = JSON.parse(sessionStorage.getItem('dataUser'));
  const sessionRetryPorta = JSON.parse(sessionStorage.getItem('portaRetry'));
  const [codVendedor] = useState(sessionDate.codigoUser);

  const [numPortar] = useState(sessionRetryPorta.numeroportar);
  const [cdaPortar] = useState(sessionRetryPorta.cda);
  const [duiPortar] = useState(sessionRetryPorta.num_documento);
  const [nip, setNip] = useState(0);
  const [tipoActivacion] = useState(2);

  useEffect(() => {
    FloatLabel.init();
  },[]);

  // Solicitud AXIOS para Obtener Info Trx_actcom
  const getInfoTrx_actcom = async () => {
    await axios
      .get(`${baseUrl.url}actividad/getInfoTrx_actcom`, {
        params: {
          tipo: tipoActivacion,
          cda: cdaPortar,
          numeroPortar: numPortar,
          codVendedor
        }
      })
      .then((response) => {
        if(response.data.status === 200){
          var infoPorta = response.data.data[0];
          var network = 0;

          if (infoPorta.donor === 'claro') {
            network = 11
          }
          if (infoPorta.donor === 'tigo') {
            network = 21
          }
          if (infoPorta.donor === 'digicel') {
            network = 12
          }

          const datos = {
            firstName: infoPorta.nombres,
            lastName: infoPorta.apellidos,
            individualIdentification: infoPorta.num_doc,
            individualIdentificationType: 'DUI',
            fullAddress: infoPorta.direccion,
            msisdn: infoPorta.numero_portar,
            routingNetwork: network,
            temporalMsisdn: infoPorta.telefono,
            accountTypeCustomerAccount: 'PRE',
            nip: nip,
            locations: '/Portacionespdf',
            documentType: 'SP',
            name: '50a87cfc2b4847eeaea036804aa99cdc.pdf',
            description: 'Submit Port In Request',
            sellerID: '25289',
            swCustomerId: '16255288',
            partialPorting: 'true',
            donorOperator: infoPorta.donor,
            receptorOperator: 'telefonica',
            technology: 'MO',
            donorAccountType: 'PRE',
          }

          const datosCodificado = encodeURIComponent(
            JSON.stringify(datos)
          )

          nuevaPortabilidad(datosCodificado).then((response) => {

            var sessionData = sessionStorage.getItem('portaRetry');
            const data = {
              paso: 8,
              telefono: sessionData.telefono, // Numero temporal que se asigna luego de la activación del CDA
              sales_order: infoPorta.sales_order,
              pdf: infoPorta.pdf,
              finalizado: true,
              trx: infoPorta.id_trx,
              tipo: 2,
              cda: sessionData.cda,
              codVendedor: sessionStorage.getItem('codVendedor'),
              numero_portar: sessionData.numeroportar, //Número de telefono a portar ingresado desde el formulario
              request_id_spn: response['request_id_spn'],
            }
            const dataCodificada = encodeURIComponent(
              JSON.stringify(data)
            )
            CreateDataSolicitud(dataCodificada).then((response) => {
              envioDatosBot(data).then((response) => {
                console.log(response)

                if (response['status'] === 1) {
                  setTimeout(() => {
                    document.getElementById("loading").hidden = true;
                    document.getElementById("alertError").hidden = false;
                    document.getElementById("btnSubmit").hidden = true;
                  }, 1000);
                }else{
                    document.getElementById("loading").hidden = true;
                    document.getElementById("alertError").hidden = true;
                    document.getElementById("btnSubmit").hidden = false;
                }
              })
            })

          })
          
        }else{
            console.log(response.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const reingresoPortaForm = async e => {
    e.preventDefault();

    document.getElementById("loading").hidden = false;
    document.getElementById("alertError").hidden = true;
    document.getElementById("btnSubmit").hidden = true;

    await getInfoTrx_actcom();

  }
  
  return (
    <div className="container h-100">
      <form className="row g-4" onSubmit={reingresoPortaForm}>
          <div className="col-md-3">
            <label htmlFor="validationCustom01" className="form-label">Número Portar</label>
            <input type="text" className="form-control" id="validationCustom01" value={numPortar} required readOnly/>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label">CDA</label>
            <input type="text" className="form-control" id="validationCustom02" value={cdaPortar} required readOnly/>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom03" className="form-label">DUI</label>
            <input type="text" className="form-control" id="validationCustom03" value={duiPortar} required readOnly/>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label">Digite Cip Recibido</label>
            <input type="text" className="form-control" id="validationCustom04" required onChange={e => setNip(e.target.value)}/>
          </div>
          <div className="col-12 text-center" >
            <div className="alert alert-success text-center" id="alertError" role="alertError" hidden>Ingreso Satisfactorio <br></br><a className="btn btn_verde btn-sm" href='/registeractivity'>Ir a Resumen</a></div>
          </div>
          <div className="col-12 text-center">
            <button className="btn btn_verde" type="submit" id="btnSubmit">Reingresar Solicitud</button>
            <img src={loading} alt="validando-reingreso" id="loading" hidden />
          </div>
        </form>
    </div>
  );
}

export default FormSolicitudReingresoPorta;
