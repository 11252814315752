import { useState } from 'react'
import Header from '../../components/Header'
import FormPortabilidad from '../../components/portabilidad/FormPortabilidad'
import { obtenerIdGestion } from '../../services/DbServices'

const PagePortabilidad = () => {
  const [idGestion, setIdGestion] = useState()
  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  return (
    <div>
      <Header
        title={'Portabilidad'}
        subT={'Completa los datos para iniciar el proceso de portación.'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <FormPortabilidad />
    </div>
  )
}

export default PagePortabilidad
