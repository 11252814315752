import { useState } from 'react'
import Header from '../../components/Header'
import Firma from '../../components/Firma'
import { obtenerIdGestion } from '../../services/DbServices'

const PageFirma = () => {
  const [idGestion, setIdGestion] = useState()
  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  return (
    <div>
      <Header subT={'Firma digital'} idGestion={`Id gestión: ${idGestion}`} />
      <Firma />
    </div>
  )
}

export default PageFirma
