import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function crearPdfHome(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}create-temp-pdf`, { data: data })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function crearPdfFinal(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}create-pdf`, data)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

