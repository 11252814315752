const data = JSON.parse(sessionStorage.getItem("data_perfilamiento")) || {};
const { nombres: nombre = "Undefined", num_doc: doc = "Undefined" } = data;

const docFormat = doc.replace(/\D/g, '');
const doc_text = docFormat.length === 9 ? "DUI" : "NIT"; 

export const Clausulas = [
    {
        title: `Clausula adicional de Libre Discusion Para Consultar Informacion Personal y Crediticia`,
        message: `Yo, <b> ${nombre} </b> con ${doc_text} Número <b>${doc}</b>,
    en adelante el CLIENTE, en el ejercicio de mi derecho de consentir en la 
    consulta de mi información, regulado por el Artículo 15 de la Ley de 
    Regulación de los Servicios de Información Sobre el Historial de Crédito 
    de las Personas y en aplicación de lo establecido en los Artículos 18 
    literal g) de la Ley de Protección al Consumidor autorizo a TELEFÓNICA 
    MÓVILES EL SALVADOR, S.A. de C.V., en adelante TELEFÓNICA, 
    para que pueda consultar y verificar mis datos personales y de historial 
    crediticio con las siguientes agencias de Información de datos: 
    Equifax Centroamerica, S.A. de C.V., Transunion El Salvador S.A. de C.V. e 
    Informacion de referencias crediticas en Red S.A. de C.V. las cuales se 
    encuentran debidamente autorizadas por la Superintendencia del Sistema 
    Financiero para tratar, recolectar, registrar, procesar y distribuir,compartir y 
    transfierir datos personales referente al comportamiento crediticio de las 
    personas con base a la regulación vigente. Esta autorización la otorgo con el 
    objeto que TELEFÓNICA pueda realizar el análisis de riesgo, previo a la 
    contratación de sus servicios. <br> <br> VO. Pers. Natural - Junio 2023 <br> 
    Depositado en la Defensoría del Consumidor en fecha 20 de junio de 2023
    `
    },
    {
        title: `Clausula Adicional De Libre Discusion Para Recopilacion y Transmision Personal y Crediticia`,
        message: `Yo, <b> ${nombre} </b> con numero <b>${doc}</b>,
    en adelante el CLIENTE, en el ejercicio de mi derecho de consentir en la recopilación y 
    transmisión de mi información regulado por el Artículo 14 literal d) de la Ley de 
    Regulación de los Servicios de Información Sobre el Historial de Crédito de las Personas 
    y en aplicación de lo establecido en los Artículos 18 literal g) de la Ley de Protección 
    al Consumidor autorizo a TELEFÓNICA MÓVILES EL SALVADOR, S.A. de C.V., en adelante 
    TELEFÓNICA, para que durante la vigencia de la relación contractual que se deriva de la 
    prestación de servicios de telecomunicaciones: a) recopile o almacene, comparta, reporte o 
    transmita, mis datos personales y de historial crediticio con las siguientes agencias de 
    información: Equifax Centroamérica, S.A. de C.V., Transunion El Salvador, S.A. de C.V. e 
    Información de Referencias Crediticias en Red, S.A. de C.V. Lo anterior, con el objeto que 
    dicha información pase a formar parte de mi historial crediticio, en las bases de datos 
    que al efecto llevan las referidas agencias; y b) Recopilar o almacenar, adicionar y 
    modificar mis datos personales y de historial crediticio, asimismo actualice cualquier 
    información crediticia proporcionada por el usuario en esta fecha y cualquier otra 
    información que TELEFÓNICA me requiera posteriormente con las agencias de información 
    antes mencionadas.`,
    },
    {
        title: `Autorizacion Para Recopilacion y  Transmision de Informacion Para Notificacion de 
        Consulta de Historial Crediticio por los Diferentes Agentes Economicos.`,
        message: `Yo, <b> ${nombre} </b>, Documento Unico de Identidad número <b>${doc}</b>, en adelante el CLIENTE, en el 
        ejercicio de mi derecho de información y de consentir en la recopilación y transmisión de 
        mi información regulados en el Artículo 14 literal a) y d) respectivamente, de la Ley de 
        Regulación de los Servicios de Información Sobre el Historial de Crédito de las Personas 
        (LRSISHCP), autorizo a TELEFÓNICA MÓVILES EL SALVADOR, S.A. de C.V., en adelante TELEFÓNICA, 
        para que: a) recopile o almacene, comparta, reporte o transmita mi número móvil de contacto 
        y/o correo electrónico con las siguientes agencias de información: Equifax Centroamérica, 
        S.A. de C.V., Transunion El Salvador, S.A. de C.V., Información de Referencias Crediticias 
        en Red, S.A. de C.V. y Asociación Protectora de Créditos de El Salvador. Lo anterior, con el 
        objeto de que dicha información sea utilizada por dichas agencias para que se me notifique 
        cuando algún Agente Económico consulte mi historial crediticio según lo establecido en el 
        artículo 17 letra h) de la LRSISHCP.`,
    }

]