import axios from 'axios'
import { baseUrl } from '../components/baseURL'

export function activarLinea(datos) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl.url}activa-prepago`,{data:datos})
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
